import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./useritem.module.sass";
import { useParams } from "react-router-dom";
import API, { axiosInstance } from "../../utils/API";
import Card from "./../../components/Card";
import { useSelector } from 'react-redux';
import ReactPaginate from "react-paginate";
import axios from '../../utils/API';
import {CircularProgress} from "@material-ui/core";

const navLinks = ["Info", "Description"];

const UserItem = () => {
  const { id } = useParams();
  const [params, setParams] = useState({ limit: 9, page: 1 });
  const [count, setCount] = useState(5);
  const [assets, setAssets] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const loginStatus = useSelector((state) => state.counter.value)
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const [isLoading, setIsLoading] = useState(true);

  const getWindowSize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    getAllAssets();
    window.addEventListener('resize', getWindowSize)

    return () => window.removeEventListener('resize', getWindowSize)
  }, [params]);

  const getAllAssets = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      params
    };

    try {
      let userData = await API.get("user/orders/purchased/", config);
      setAssets(userData?.data?.result?.orders?.rows);
      setCount(userData?.data?.result?.totalPages);
      //setIsLoading(false)
    } catch (err) {
      console.log(err);
      //setIsLoading(false)
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log(selectedPage, "hello");
    setParams({ ...params, page: selectedPage + 1 })
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div
            className={styles.title}
            style={{
              borderBottom: "0.063rem solid #999",
              width: "100%",
              padding: "1.25rem 0px",
            }}
          >
            Purchased Assets
          </div>
          {isLoading && (
              <div className={styles.loaderContent}>
                <CircularProgress style={{color: "#FFF"}} />
              </div>
          )}
          {loginStatus ?

            <div className={styles.wrapper}>
              {assets?.length !== 0 ? (
                <>
                  <div className={styles.list}>
                    {assets.map((x, index) => (
                      <Card
                        className={styles.card}
                        ethAmount={x.ethPrice}
                        item={x.asset}
                        key={index}
                        type={"view"}
                        orderId="157"
                        showLabel={true}
                        price={euroValue}
                      />
                    ))}
                  </div>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={count}
                    onPageChange={handlePageClick}
                    containerClassName={styles.pagination}
                    previousLinkClassName={cn(styles.pagination__link, "page_switch")}
                    nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                    pageClassName={"page_no"}
                    disabledClassName={styles.pagination__link__disabled}
                    activeClassName={styles.pagination__link__active}
                    pageRangeDisplayed={width < 510 ? 1 : 3}
                    marginPagesDisplayed={width < 510 ? 1 : 3}
                  />
                </>
              ) : (
                <div>
                  {" "}
                  No purchases yet, click on categories to explore products.
                </div>
              )}
            </div>
            : <div className={styles.wrapper}>
              <div style={{ textAlign: "center", fontSize: '0.938rem' }}>Connect Wallet to view products.</div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default UserItem;
