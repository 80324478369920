import { v4 as uuidv4 } from "uuid";

const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

export const wertOtherWidgetOptions = ["PRODUCTION"].includes(
  SERVER_ENVIORNMENT
)
  ? {
      partner_id: "01H4ZPTZT5WZGA86RBDMCPPZGE", // your partner id
      container_id: "widget",
      click_id: uuidv4(), // unique id of purhase in your system
      origin: "https://widget.wert.io",
      width: 1400,
      height: 600,
    }
  : ["UAT"].includes(SERVER_ENVIORNMENT)
  ? {
      partner_id: "01GSX0Q2W016CVJFDHQX2MBENS", // your partner id
      container_id: "widget",
      click_id: uuidv4(), // unique id of purhase in your system
      origin: "https://sandbox.wert.io", // this option needed only in sandbox
      width: 1400,
      height: 600,
    }
  : {
      partner_id: "01H8E61EH0AVC1JRX6KWE2TPBM", // your partner id
      container_id: "widget",
      click_id: uuidv4(), // unique id of purhase in your system
      origin: "https://sandbox.wert.io", // this option needed only in sandbox
      width: 1400,
      height: 600,
    };

export const wertTopUpWidgetOptions = ["PRODUCTION"].includes(
  SERVER_ENVIORNMENT
)
  ? {
      partner_id: "01H4ZPTZT5WZGA86RBDMCPPZGE",
      origin: "https://widget.wert.io",
      theme: "white",
      commodities: JSON.stringify([
        { commodity: "USDCc", network: "polygon" },
        { commodity: "MATIC", network: "polygon" },
      ]),
      commodity: "MATIC",
      network: "polygon",
      lang: "en",
      widgetLayoutMode: "Modal",
      click_id: uuidv4(),
    }
  : ["UAT"].includes(SERVER_ENVIORNMENT)
  ? {
      partner_id: "01GSX0Q2W016CVJFDHQX2MBENS",
      container_id: "wert-widget",
      origin: "https://sandbox.wert.io",
      theme: "white",
      commodity: "MATIC",
      network: "mumbai",
      commodities: JSON.stringify([
        { commodity: "TT", network: "mumbai" },
        { commodity: "MATIC", network: "mumbai" },
      ]),
      lang: "en",
      click_id: uuidv4(),
    }
  : {
      partner_id: "01H8E61EH0AVC1JRX6KWE2TPBM",
      container_id: "wert-widget",
      origin: "https://sandbox.wert.io",
      theme: "white",
      commodity: "BNB",
      network: "bsc",
      commodities: JSON.stringify([
        { commodity: "BNB", network: "bsc" },
        { commodity: "TT", network: "bsc" },
      ]),
      lang: "en",
      click_id: uuidv4(),
    };
