import React, { useState, useEffect } from "react";
import styles from "./useritem.module.sass";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { current_datetime } from "../../controller/utils";
import { useHistory } from "react-router";
import cn from "classnames";
import useWindowSize from "../../common/hooks/useWindowSize";
import moment from "moment";

const CheckoutReceipt = (props) => {
	const order = props.location?.state?.details;
	const name = props.location.state.name;
	const datetime = new Date();
	const history = useHistory();
	const polygon = process.env.REACT_APP_POLYGON_LINK;
	const [screenWidth] = useWindowSize();

	return (
		<div
			className={cn("container", styles.container)}
			style={{ marginBottom: 50 }}
		>
			<div
				className={styles.title}
				style={{
					width: "100%",
					padding: "1.25rem 0px",
				}}
			>
				<div
					className={styles.backArrowCircle}
					onClick={() => history.goBack()}
				>
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="40" height="40" rx="20" fill="#3E2F5F"/>
						<path d="M23.6898 15.7408L19.4368 20.0288L23.6898 24.2568C23.7877 24.3483 23.8657 24.4589 23.9191 24.5818C23.9725 24.7047 24 24.8373 24 24.9713C24 25.1053 23.9725 25.2378 23.9191 25.3607C23.8657 25.4836 23.7877 25.5943 23.6898 25.6858C23.5999 25.7843 23.4903 25.863 23.3683 25.9169C23.2462 25.9707 23.1142 25.9985 22.9808 25.9985C22.8474 25.9985 22.7154 25.9707 22.5934 25.9169C22.4713 25.863 22.3618 25.7843 22.2718 25.6858L17.3098 20.7428C17.2119 20.6513 17.1339 20.5406 17.0805 20.4177C17.0272 20.2948 16.9996 20.1623 16.9996 20.0283C16.9996 19.8943 17.0272 19.7617 17.0805 19.6388C17.1339 19.5159 17.2119 19.4053 17.3098 19.3138L22.2718 14.3138C22.3616 14.2151 22.471 14.1362 22.593 14.0822C22.715 14.0282 22.8469 14.0002 22.9804 14C23.1138 13.9998 23.2458 14.0274 23.3679 14.0811C23.4901 14.1348 23.5997 14.2133 23.6898 14.3118C23.8733 14.5058 23.9799 14.7599 23.9898 15.0268C23.9905 15.16 23.9643 15.292 23.9127 15.4149C23.8612 15.5377 23.7854 15.6489 23.6898 15.7418L23.6898 15.7408Z" fill="url(#paint0_linear_187_15756)"/>
						<defs>
							<linearGradient id="paint0_linear_187_15756" x1="23.489" y1="16.0757" x2="15.0093" y2="19.7529" gradientUnits="userSpaceOnUse">
								<stop stop-color="#04DCFD"/>
								<stop offset="1" stop-color="#1F90FA"/>
							</linearGradient>
						</defs>
					</svg>

				</div>
				<div className={styles.pageTitle}> Transaction details</div>
			</div>
			<div
				className={
					screenWidth > 766
						? styles.detailsCheckout
						: styles.mobileDetailsCheckout
				}
				style={{ padding: 10 }}
			>
				<div className={styles.col_1}>
					{" "}
					<div className={styles.productDetails}>
						<div>
							{order?.asset?.mediaType === "audio" && (
								<div
									className={styles.imagePlayerWrapper}
									style={{
										position: "relative",
										paddingTop: 0,
									}}
								>
									{/* <div> */}
									<img
										src={order?.asset?.audioThumbnail}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											borderRadius: "0.625rem",
										}}
										alt="thumbnail.png"
									/>
									<audio
										controls
										controlsList="nodownload"
										style={{
											position: "absolute",
											bottom: 0,
											left: 0,
											height: 25,
											width: "100%",
										}}
									>
										<source src={order?.mediaPreviewUrl} type="audio/mpeg" />
									</audio>
									{/* </div> */}
								</div>
							)}
							{order?.asset?.mediaType === "video" && (
								<div
									className={styles.imagePlayerWrapper}
									// style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
									style={{
										position: "relative",
										paddingTop: 0,
									}}
								>
									<ReactPlayer
										url={
											order?.asset?.mediaPreviewUrl
												? order?.asset?.mediaPreviewUrl
												: ""
										}
										//   url={order?.mediaPreviewUrl}
										width="100%"
										height="100%"
										controls={true}
										light={false}
										// style={{ position: "absolute", top: "0", left: "0" }}
										style={{ borderRadius: "0.625rem" }}
										config={{
											file: {
												attributes: {
													disablePictureInPicture: true,
													controlsList: "nodownload",
												},
											},
										}}
									/>
								</div>
							)}
							{(order?.asset?.mediaType === "image" ||
								order?.asset?.mediaType === "3D Model") && (
								<div
									className={styles.imagePlayerWrapper}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										// objectFit: "cover",
									}}
								>
									<img
										src={
											order?.asset?.thumbnailUrl ||
											order?.asset?.mediaPreviewUrl
										}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											borderRadius: "0.625rem",
										}}
										alt="thumbnail.png"
									/>
								</div>
							)}
							{/* <span style={{ marginLeft: 0 }}>
              {"Product Name: " + order?.name}
            </span> */}
						</div>
					</div>
				</div>
				{screenWidth > 766 ? (
					<WebUi order={order} polygon={polygon} props={props} />
				) : (
					<MobileUi order={order} polygon={polygon} props={props} />
				)}
				{/* <div>
          <div className={styles.singleDetail1}>Product Name</div>
          <div className={styles.singleDetail}>{name}</div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Ordered at</div>
          <div className={styles.singleDetail}>
            {" "}
            {current_datetime(order?.createdAt) || "-"}
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Transaction Hash</div>
          <div className={styles.singleDetail}>
            <a
              href={`${process.env.REACT_APP_POLYGON_LINK}tx/${order?.orderTx}`}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#ff7cba" }}
            >
              {order?.orderTx || "-"}
            </a>
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Payment method</div>
          <div className={styles.singleDetail}>
            {" "}
            {props.location.state?.paymentType || "Ethereum Wallet"}
          </div> */}
			</div>
			{/* <div >
          <div className={styles.singleDetail}>Order Number</div>
          <div className={styles.singleDetail}> {order?.id || "-"} </div>
        </div> */}
			{/* <div>
          <div className={styles.singleDetail1}> Buyer Address</div>
          <div className={styles.singleDetail}> {order?.from || "-"} </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Quantity</div>
          <div className={styles.singleDetail}>{order?.quantity || "-"} </div>
        </div> */}
			{/* <div >
          <div className={styles.singleDetail}>Seller Address</div>
          <div className={styles.singleDetail}>{order?.to || "-"}</div>
        </div> */}
			{/* <div>
          <div className={styles.singleDetail1}>Price</div>
          <div className={styles.singleDetail}>
            &#36; {(order?.unitPrice).toFixed(2) || "-"}
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>NFT Id</div>
          <div className={styles.singleDetail}>
            {(order?.orderTx ? order?.assetId : "-") || "-"}
          </div>
        </div>
        <div>
          <div className={styles.singleDetail1}>Contract Number</div>
          <div className={styles.singleDetail}>
            {(order?.orderTx ? process.env.REACT_APP_CONTACT_NO : "-") || "-"}
          </div>
        </div> */}
			{/* </div> */}
		</div>
	);
};

const WebUi = ({ order, polygon, props }) => {
	return (
		<>
			<div className={styles.col_2}>
				<GetInfoRow
					label="Product name"
					data={order?.asset?.name ? order?.asset?.name : "-"}
				/>
				<GetInfoRow
					label="Transaction hash"
					data={
						order?.orderTx ? (
							<a
								href={`${polygon}tx/${order?.orderTx}`}
								target="_blank"
								rel="noreferrer"
							>
								{order?.orderTx || "-"}
							</a>
						) : (
							"-"
						)
					}
				/>
				<GetInfoRow label="Buyer address" data={order?.from || "-"} />
				<GetInfoRow
					label="Price"
					data={<>&#36; {(order?.unitPrice).toFixed(2) || "-"}</>}
				/>
				<GetInfoRow
					label="Contract address"
					data={
						(order?.orderTx
							? process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV
							: "-") || "-"
					}
				/>
			</div>
			<div className={styles.col_3}>
				<GetInfoRow
					label="Ordered at"
					data={moment.utc(order?.createdAt).local().format('DD/MM/YYYY h:mm:ss A') || "-"}
				/>
				<GetInfoRow
					label="Payment method"
					data={props.location.state?.paymentType || "Ethereum Wallet"}
				/>
				<GetInfoRow label="Quantity" data={order?.quantity || "-"} />
				<GetInfoRow
					label="NFT id"
					data={(order?.asset?.tokenId ? order?.asset?.tokenId : "-") || "-"}
				/>
			</div>
		</>
	);
};

const MobileUi = ({ order, polygon, props }) => {
	return (
		<>
			<div className={styles.col_2}>
				<GetInfoRow
					label="Product Name"
					data={order?.asset?.name ? order?.asset?.name : "-"}
				/>
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: "1.25rem" }}>
						<GetInfoRow
							label="Ordered at"
							data={moment.utc(order?.createdAt).local().format('DD/MM/YYYY h:mm:ss A') || "-"}
						/>
					</div>
					<GetInfoRow
						label="Price"
						data={<>&#36; {(order?.unitPrice).toFixed(2) || "-"}</>}
					/>
				</div>
				<GetInfoRow
					label="Transaction Hash"
					data={
						order?.orderTx ? (
							<a
								href={`${polygon}tx/${order?.orderTx}`}
								target="_blank"
								rel="noreferrer"
							>
								{order?.orderTx || "-"}
							</a>
						) : (
							"-"
						)
					}
				/>
				<GetInfoRow
					label="Payment method"
					data={props.location.state?.paymentType || "Ethereum Wallet"}
				/>
				<GetInfoRow label="Buyer address" data={order?.from || "-"} />
				<GetInfoRow label="Quantity" data={order?.quantity || "-"} />
				<GetInfoRow
					label="NFT Id"
					data={(order?.asset?.id ? order?.asset?.id : "-") || "-"}
				/>
				<GetInfoRow
					label="Contract Address"
					data={
						(order?.orderTx
							? process.env.REACT_APP_CONTRACT_ADDRESS_DEV
							: "-") || "-"
					}
				/>
			</div>
		</>
	);
};

const GetInfoRow = ({ label, data }) => {
	return (
		<div className={styles.infoRow}>
			<div className={styles.infoLabel}>{label}</div>
			<div className={styles.infoContent}>{data}</div>
		</div>
	);
};

export default CheckoutReceipt;
