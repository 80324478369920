import React, { useEffect } from "react";
import styles from "./Item.module.sass";
import { CircularProgress } from "@material-ui/core";

const Loader = (props) => {
	const {
		active,
		customize = false,
		title = null,
		content = null,
		type = null,
		backGround = null,
	} = props;


	return (
		<div
			className={styles.checkout + " " + styles.LoaderPopup}
			style={{ display: active ? "flex" : "none" }}
		>
			<div
				className={styles.checkoutInner}
				style={
					backGround
						? {
								height: "35%",
								// background: `url(${backGround}) center center`,
								backgroundSize: "cover",
								alignItems:"center"
						  }
						: { minHeight: "20%", height: 'auto' }
				}
				onClick={(e) => e.stopPropagation()}
			>
				<h2>{customize ? title : "Transaction in progress"}</h2>
				<div className={styles.checkoutDetail}>
					<div>
						{type !== "no" && (
							<p style={{ textAlign: "center" }}>
								{customize ? content : "Item purchase in progress"}
							</p>
						)}
						<p style={{ textAlign: "center" }}>
							Please do not refresh the page
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loader;
