import React from "react";
import BalanceWidget from "../../components/BalanceWiget/BalanceWidget";
import AnalyticsWidget from "./components/AnalyticsWidget";
import styles from "./Analytics.module.sass";
import { useSelector } from "react-redux";

function Analytics() {
  const isLogin = useSelector((state) => state.counter.value);
  return (
    <div className={styles.analyticsContainer}>
      {isLogin ? (
        <>
          <AnalyticsWidget />
          {/*<BalanceWidget />*/}
        </>
      ) : (
        <span>Login to view analytics.</span>
      )}
    </div>
  );
}

export default Analytics;
