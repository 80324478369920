import { useHistory } from "react-router";
import styles from "./viewprofile.module.sass";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatPrice } from "../../controller/utils";
const ProfileCard = ({ details, currentTab, profileDetails }) => {
  const history = useHistory();
  const walletStatus = useSelector((state) => state?.counter?.value);
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  const item = details.Assets;
  const cardImageValue = (type) => {
    if (type == "audio") {
      return (
        <>
          <img src={details?.Asset?.audioThumbnail} alt="logo"></img>
          <audio
            controls
            controlsList="nodownload"
            style={{
              position: "absolute",
              bottom: "1.563rem",
              left: 0,
              height: 35,
              width: "100%",
            }}
          >
            <source src={details?.Asset?.mediaPreviewUrl} type="audio/mpeg" />
          </audio>
        </>
      );
    } else if (type == "image" || type == "3D Model") {
      return (
        <img
          src={details?.Asset?.thumbnailUrl || details?.Asset?.mediaPreviewUrl}
          alt="logo"
        ></img>
      );
    } else if (type == "video") {
      return (
        <div>
          <video
            id="vid"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            disablePictureInPicture
            controlsList="nodownload"
            loop={true}
            autoPlay="autoplay"
            muted
          >
            <source src={details?.Asset?.mediaPreviewUrl} />
            Your browser does not support the video.
          </video>
        </div>
      );
    }
  };

  const getPrice = (asset) => {
    if (asset?.saleType == "fixed") {
      if ( asset?.isOnSell === true) {
        return asset?.resellPrice;
      }
      return asset?.regularPrice;
    } else {
      return asset?.auction?.currentBid;
    }

  };
  return (
    <>
      <div className={styles.card}>
        <div
          className={styles.previewImage}
          onClick={() => {
            console.log(currentTab);
            if (currentTab == 1) {
              console.log("Clicked");
              history.push(`/item/${details?.Asset?.slug}`);
            }
          }}
        >
          {cardImageValue(details?.Asset?.mediaType)}
        </div>
          <div className={styles.assetName}>
              {details?.Asset?.name}
          </div>
        <div>
          {/*<div className={styles.logoOwnerWishlist}>*/}
          {/*  <div className={styles.logoOwner}>*/}
          {/*    <img*/}
          {/*      src={*/}
          {/*        profileDetails?.profilePicUrl || "/images/content/blue-avatar.png"*/}
          {/*      }*/}
          {/*      alt="logo"*/}
          {/*    ></img>*/}
          {/*    <div>*/}
          {/*      <h5>{currentTab == 0 ? "Owner" : "Creator"}</h5>*/}
          {/*      <p>@{profileDetails?.userName}</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div></div>*/}
          {/*</div>*/}

            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                minHeight: '60px'
            }}>
              <div className={styles.priceRow}>
                <p>Price</p>
                <p className={styles.priceRowValue}>${getPrice(details?.Asset)}</p>
              </div>
              <div className={styles.buyNowOrBidNow}>
                {currentTab == 1 && details?.Asset?.saleType === "fixed" && !details?.Asset?.isAirdropNft &&
                  <>
                   {getPrice(details?.Asset) > 0 ?  <button className={styles.buy} onClick={() => {
                      history.push(`/item/${details?.Asset?.slug}`);
                    }}>Buy Now</button> : <button className={styles.buy} disabled>Can't Purchase this Asset</button>}
                  </>
                }
                   {currentTab == 1 && details?.Asset?.saleType === "fixed" && details?.Asset?.isAirdropNft &&
                  <>
                   {moment.utc(details?.Asset?.airdropEndDate).isBefore(moment.utc()) ? <button className={styles.buy} disabled>Expired</button> : details?.Asset?.isAirdropRequested ?  <button className={styles.buy} disabled>Asset already requested</button> : <button className={styles.buy} onClick={() => {
                      history.push(`/item/${details?.Asset?.slug}`);
                    }}>Claim</button> }
                  </>
                }
                {currentTab == 1 && details?.Asset?.saleType === 'auction' &&
                  <>
                    {details?.Asset?.status === 'active' ? <button className={styles.buy} onClick={() => {
                      history.push(`/item/${details?.Asset?.slug}`);
                    }}>Bid Now</button> : <button className={styles.buy} disabled>Expired</button>}
                  </>}
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
