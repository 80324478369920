import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 970,
    borderColor: 'transparent',
    color: 'rgba(139, 156, 169, 1)',
    cellPadding: '1rem'
  },
  desc:{
    width:'200px'
  },
  col:{
    fontWeight:'bold',
    color: 'rgba(139, 156, 169, 1)',
    borderColor: 'transparent'
  },
  row: {
    color: '#FFF',
    borderColor: 'transparent',
    backgroundColor: '#3E2F5F'
  }
});



const col = ['Asset Name', "Commission Amount", "Commission Type", "Status","Remark" ]




export default function CustomTable(props) {
  const classes = useStyles();
  const {data} = props


  return (
    <TableContainer component={Paper} style={{
      backgroundColor: 'transparent', border: '0px', boxShadow: 'none'
    }}>
      <Table className={classes.table} aria-label="simple table" style={{
        boxShadow: '0px'
      }}>
        <TableHead>
          <TableRow>


            {col.map(e=>
            <TableCell className={classes.col} align="center">{e}</TableCell>
                )}

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id} >
              <TableCell align="center" component="th" scope="row" className={classes.row}>
              <a
                  href={`
                  ${process.env.REACT_APP_POLYGON_LINK}tx/${row?.order?.orderTx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#FFF',
                    textDecoration: 'underline'
                  }}
                >
                  { row?.asset.currentEdition ?
                    row?.asset?.name :
                    // row?.asset?.name + " #" + row?.asset?.currentEdition+ "/" +row?.asset?.totalEdition:
                    row?.asset?.name }

              </a>
              </TableCell>


              <TableCell align="center" className={classes.row}>$
                        {row?.paidAmount
                          ? `${row?.paidAmount} (${row?.userAssetCommission?.percentage}%)`
                          : "-"}
              </TableCell>
              <TableCell align="center" className={classes.row}>
                {row?.userAssetCommission?.type}
              </TableCell>
              <TableCell align="center" className={classes.row}>

              {row?.status}
              </TableCell>
              <TableCell align="center" width = '30%' className={classes.row}>

              {row?.remark ? row?.remark : "-"}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
