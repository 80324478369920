import React from 'react'
import styles from './selectWallet.css'
import { Button, Select } from '@material-ui/core'
function SelectWallet({wallet, setWallet, setOpenTopUpModal, setOpenWert}) {
  return (
    <>
        <h3>Select Token</h3>
        <select className='selectWallet'
                name="wallet"
                value={wallet}
                onChange={(e) => setWallet(e?.target?.value)}
                label="Wallet"
                onClose={()=> {}}
            >
                    <option value = {'USDC'} key = {0} style = {{height:'40px'}}>{'USDC'}</option>
                    <hr></hr>
                    <option value = {'MATIC'} key = {1} style = {{height:'40px'}}>{'MATIC'}</option>

        </select>
        <div style = {{display:'flex', justifyContent:'flex-end', marginTop:20}}>
                <Button className={'cancelButton'} style = {{ marginRight:'10px', textTransform:'capitalize'}}>Cancel</Button>
                <Button className='confirmButton' onClick={()=>setOpenWert(true)}>Confirm</Button>
        </div>
        </>
  )
}

export default SelectWallet
