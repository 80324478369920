import React, { useState, useEffect } from "react";
import cn from 'classnames';
import styles from './Auction.module.sass';
import { axiosInstance } from "../../utils/API";
import { useSelector, useDispatch } from 'react-redux';
import Web3 from "web3";
import ReactPlayer from "react-player";
import Swal from 'sweetalert2';
import { current_datetime, formatPrice, PopUpAlert } from "../../controller/utils";
import ReactPaginate from "react-paginate";

const BidCards = (props) => {
  const [details, setDetails] = useState([]);
  const [params, setParams] = useState({ limit: 10, page: 1, type: "won" });
  const [count, setCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const datetime = new Date();
  const { index } = props;
  const value = useSelector((state) => state.counter.euroValue);

  const getAllAssets = async (index) => {
    let parameters = params;
    switch (index) {
      case 0:
        parameters = { ...params, type: "active" };
        break;
      case 1:
        parameters = { ...params, type: "won" };
        break;
      case 2:
        parameters = { ...params, type: "lost" };
        break;
      default:
        break;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      params: parameters
    };
    try {
      let userData = await axiosInstance.get("asset/auction/bidder-details", config);
      setDetails(userData?.data?.result?.Data?.rows);
      setCount(userData?.data?.result?.totalPages);
    } catch (err) {
      setDetails([]);
      PopUpAlert('Alert', err?.response?.data?.message, "warning")
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log(selectedPage, "hello");
    setParams({ ...params, page: selectedPage + 1 })
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  const getWindowSize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    getAllAssets(index);
    window.addEventListener('resize', getWindowSize)

    return () => window.removeEventListener('resize', getWindowSize)
  }, [props, params])

  // const handleWithdraw = async (id) => {
  //   let web3 = new Web3(Web3.givenProvider);
  //   const contract_address_bid = '0x464D4cC81A27463A5960a08250A62C0662727CAA';
  //   // const contract_address = '0x38DB5fcef713b61AD4e2eb1C17a2bbf6fB7EADBe';
  //   const account = await web3.eth.getAccounts();
  //   try {
  //     const auction_contract = await new web3.eth.Contract(contract_auction_abi, contract_address_bid);
  //     const bidApprove = await web3.eth.sendTransaction({
  //       from: account[0],
  //       to: contract_address_bid, // erc20 address
  //       value: 0,
  //       data: auction_contract.methods.withdraw(id).encodeABI(),
  //     })
  //     if (bidApprove) {
  //       Swal.fire('Great', 'withdrawal Successful', 'info');
  //     }
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }

  // }

  return (
    <div style={{ padding: "1.875rem 0px", margin: "auto" }}>
      {details?.length === 0 &&
        <>
          {index === 2 &&
            <div className={styles.info}>
              No bids Lost Yet
            </div>
          }
          {index === 1 &&
            <div className={styles.info}>
              No bids Won Yet
            </div>
          }
          {index === 0 &&
            <div className={styles.info}>
              No bids Avaliable Yet
            </div>
          }
        </>
      }
      {
        details?.map((asset, idx) => {
          const item = asset?.asset;
          return (
            <div className={styles.orderitem} style={{ padding: "0.625rem" }} key={idx}>
              {item.mediaType === "audio" &&
                <div
                  className="player-wrapper"
                  style={{ position: "relative", paddingTop: 0 }}
                >
                  <div style={{ width: 100, height: 90, position: "relative" }}>
                    <img
                      src={item?.audioThumbnail}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                      }}
                      alt="thumbnail.png"
                    />
                    <audio controls controlsList="nodownload"
                      style={{ position: "absolute", bottom: 0, left: 0, height: 15, width: "100%" }}>
                      <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
                    </audio>
                  </div>
                </div>
              }
              {
                item.mediaType === "video" &&
                <div
                  className="player-wrapper"
                  // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                  style={{ position: "relative", paddingTop: 0, minWidth: '6.125rem' }}
                >
                  <ReactPlayer
                    url={item?.mediaPreviewUrl}
                    width="100%"
                    height="100%"
                    controls={true}
                    light={false}
                    style={{ position: "absolute", top: "0", left: "0" }}
                    config={{
                      file: {
                        attributes: { disablePictureInPicture: true, controlsList: "nodownload" }
                      }
                    }}
                  />
                </div>
              }
              {(item?.mediaType === "image" || item?.mediaType === "3D Model") &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={styles.playerwrapper}
                >
                  <img
                    src={item?.thumbnailUrl || item?.mediaPreviewUrl}
                    style={{
                      width: "100%",
                      objectFit: "cover"
                    }}
                    alt="thumbnail.png"
                  />
                </div>
              }
              <div className={styles.itemdetails}>
                <div className={styles.itemdetailsrow}>
                  <h4>
                    {item?.name}
                  </h4>
                  {/* <h4>
                    {"Order Id: " + asset.id}
                  </h4> */}
                  {/* {index === 2 &&
                    <button className={cn("button-stroke", styles.button)}
                      onClick={()=>handleWithdraw(item.id)}
                    >
                      Withdraw
                    </button> }*/}

                </div>
                <div className={styles.itemdetailsrow}>
                  <div>
                    <p>End Date</p>
                    {current_datetime(asset?.endingDate) || "-"}
                  </div>
                  <div>
                    <p>Top bid</p>
                    &#36; {formatPrice((asset?.currentBid).toFixed(0))}
                    ( {formatPrice((asset?.currentBid * value).toFixed(4))} MATIC)
                  </div>
                  <div>
                    <p>Status</p>
                    {asset?.status}
                  </div>
                </div>
              </div>
            </div>

          )
        }
        )
      }
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        pageCount={count}
        onPageChange={handlePageClick}
        containerClassName={styles.pagination}
        previousLinkClassName={cn(styles.pagination__link, "page_switch")}
        nextLinkClassName={cn(styles.pagination__link, "page_switch")}
        pageClassName={"page_no"}
        disabledClassName={styles.pagination__link__disabled}
        activeClassName={styles.pagination__link__active}
        pageRangeDisplayed={width < 510 ? 1 : 3}
        marginPagesDisplayed={width < 510 ? 1 : 3}
      />
    </div>
  )
}

export default BidCards;
