import React, { useState, useEffect } from "react";
import cn from "classnames";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import styles from "../Popular/Popular.module.sass";
import Icon from "../../../components/Icon";
import API from "../../../utils/API";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Section } from "react-fullpage";
import useWindowSize from "../../../common/hooks/useWindowSize";
import CardHomePage from "./CardHomePage"; // import { Section } from "react-fullpage";
// import { setRefresh } from "../../../redux/utilitySlice";
// import { Section } from "react-fullpage";
import ArrowForwardTwoToneIcon from "@material-ui/icons/ArrowForwardTwoTone";
import ArrowBackTwoToneIcon from "@material-ui/icons/ArrowBackTwoTone";
import {Container} from "react-bootstrap";
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const dateOptions = ["Today", "Morning", "Dinner", "Evening"];
const directionOptions = ["Sellers", "Buyers"];

const RecentSold = ({ fixed, title, mode = 0, handleAdd }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [date, setDate] = useState(dateOptions[0]);
  const [refresh, setRefresh] = useState(false);
  const [direction, setDirection] = useState(directionOptions[0]);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  const walletStatus = useSelector((state) => state?.counter?.value);
  const [screenWidth] = useWindowSize();

  useEffect(() => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    if (mode === 3) {
      API.get("/asset/most-viewed", walletStatus ? config : "").then((res) => {
        const data = res.data?.result?.map((item) => ({ asset: item }));
        if (data?.length > 0) {
          handleAdd(title);
        }
        console.log('data', data)
        setData(data);
      });
    } else if (fixed) {
      API.get(
        "/asset/fixed/latest-twentyfive",
        walletStatus ? config : ""
      ).then((res) => {
        const data = res.data?.result?.map((item) => ({ asset: item }));
        if (data?.length > 0) {
          handleAdd(title);
        }
        setData(data);
      });
    } else {
      API.get("/asset/order/last-five", walletStatus ? config : "").then(
        (res) => {
          setData(res.data?.result);
          // if(res.data?.result.length > 0){
          //   handleAdd(title)
          // }
        }
      );
    }
  }, [refresh]);

  const setRefreshClick = () => {
    setRefresh(!refresh);
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false, //screenWidth > 1300 ? false : true,
    nextArrow: (
      <SlickArrow>
        <ArrowForwardTwoToneIcon style={{ color: "white" }} />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <ArrowBackTwoToneIcon style={{ color: "white" }} />
      </SlickArrow>
    ),
    responsive: [
      // {
      //   breakpoint: 1340,
      //   settings: {
      //     slidesToShow: 4,
      //     infinite: true,
      //   },
      // },
      // {
      //   breakpoint: 1023,
      //   settings: {
      //     slidesToShow: 4,
      //     infinite: true,
      //   },
      // },
      {
        breakpoint: 1157,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      // {
      // 	breakpoint: 1025,
      // 	settings: {
      // 		slidesToShow: 2,
      // 		infinite: true,
      // 	},
      // },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  // console.log(data);
  return (
    <>
      {data?.length > 0 && (
        <>
          {screenWidth < 600 ? (
            <Sections
              data={data}
              title={title}
              settings={settings}
              fixed={fixed}
              history={history}
              walletStatus={walletStatus}
              screenWidth={screenWidth}
            />
          ) : (
              <Container className={'lii-section'}>
            >
              <Sections
                data={data}
                title={title}
                settings={settings}
                fixed={fixed}
                history={history}
                walletStatus={walletStatus}
                screenWidth={screenWidth}
                setRefreshClick={setRefreshClick}
              />
              </Container>
          )}
          <div
              style={{
                textAlign: "center",
                marginTop: '3rem'
              }}
          >
            <Link to="/explore/all"
                  style={{
                    "&:hover": {
                      color: "#4d3bf1",
                    },
                    borderRadius: '8px',
                    border: '2px solid var(--Gradient_Blue, #04DCFD)',
                    padding: '16px 24px',
                    color: '#F2F6F9',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal'
                  }}
            >
              View more <img src={'/Icons/two-dots.svg'} />
            </Link>{" "}
          </div>
        </>
      )}
    </>
  );
};

const Sections = ({
  data,
  title,
  settings,
  fixed,
  history,
  walletStatus,
  screenWidth,
  setRefreshClick,
}) => {
  return (
    <>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.box}>
            <div className={styles.stage}>{title}</div>
          </div>
        </div>
        <div className={styles.wrapper}>
          {screenWidth < 600 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {data.slice(0, 4).map((x, i) => (
                <CardHomePage
                  key={i}
                  {...{
                    i,
                    fixed,
                    history,
                    x,
                    walletStatus,
                    document,
                    setRefreshClick,
                  }}
                />
              ))}
            </div>
          ) : (
            <Slider className="popular-slider" {...settings}>
              {data.map((x, index) => (
                <CardHomePage
                  key={index}
                  {...{
                    index,
                    fixed,
                    history,
                    x,
                    walletStatus,
                    document,
                    setRefreshClick,
                  }}
                />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentSold;
