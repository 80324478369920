import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { v4 as uuidv4 } from "uuid";
import { BigNumber } from "bignumber.js";
import {
  PopUpAlert,
  bidWithUsdcPayment,
  buyWithUsdcPayment,
  decryptData,
  getWeb3Provider,
  wertBlockChainBidMethod,
  wertBlockChainBuyMethod,
} from "../../controller/utils";
import { useEffect, useState } from "react";
import { contractDetails } from "../../utils/contract";
import { axiosInstance, axiosInstanceBlockChain } from "../../utils/API";
import { useSelector } from "react-redux";
import { wertOtherWidgetOptions } from "../../utils/config";

import axios from "axios";

const config = {
  headers: {
    Authorization: localStorage.getItem("userToken"),
  },
};

const WertWidgetDiv = ({ arg }) => {
  const {
    productDetails,
    mode,
    saleType,
    bidAmountFiat,
    bidAmountCrypto,
    bidAmountFiatWEI,
    processWertResult,
    handleWertResult,
    onCloseWidget,
    closeWert,
    setCloseWert,
    callSetSuccessShow,
    wertHookSubstitueAuction, // wertHookSubstitueAuction for updating the bid success from FE
    updateFixedOrderStatus, // for creating order updation after the payment get initiated from wert
  } = arg;

  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("userToken") !== null) {
      setAuth(localStorage.getItem("userToken"));
    }
  }, [localStorage]);

  useEffect(() => {
    if (localStorage.getItem("userToken") !== null) {
      runWertWidget(localStorage.getItem("userToken"));
    }
  }, []);

  // console.log(
  //   "wert 1 bidAmountFiat, bidAmountCrypto, bidAmountFiatWEI",
  //   bidAmountFiat,
  //   bidAmountCrypto,
  //   bidAmountFiatWEI
  // );
  // console.log("Wert Widget Is Loaded!!!!!!!. ");
  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  const userAccountDetails = useSelector((state) => state.counter.user);
  const profile = useSelector((state) => state?.profileReducer);

  const [wertProcessed, setWertProcessed] = useState(false);
  let codeExecutedTillPayment_status = "";

  // This will fetch signature from blockchain

  async function handleGetSignature(transactionDetails, token) {
    // console.log("wert signature transaction details", transactionDetails);
    // console.log("wert parameters  : ", bidAmountFiatWEI, bidAmountCrypto);
    // console.log(
    //   "wert sale Type  : ",
    //   saleType,
    //   "MATIC PRICE",
    //   saleType === "auction" ? bidAmountCrypto : transactionDetails?.maticPrice,
    //   bidAmountCrypto,
    //   "FIAT AMOUUNT",
    //   saleType === "auction"
    //     ? bidAmountFiatWEI
    //     : transactionDetails?.regularPrice
    // );

    const payload = {
      collectableId: transactionDetails?.tokenId,
      payer: userAccountDetails?.account?.[0],
      maticPrice:
        saleType === "auction"
          ? bidAmountCrypto
          : transactionDetails?.maticPrice,
      fiatPrice:
        saleType === "auction"
          ? bidAmountFiatWEI
          : transactionDetails?.regularPrice,
      isCard: true,
    };

    // posting to asly backend
    return new Promise((resolve, reject) => {
      let url = "/blockchain/signature/payWithCrypto";
      if (productDetails?.saleType == "auction") {
        url = "/blockchain/signature/bidWithUsdc";
        payload.fiatPrice = bidAmountFiatWEI;
      }

      axiosInstance
        .post(url, payload, {
          headers: { Authorization: token },
        })
        .then((res) => {
          if (res) {
            resolve(res);
          } else {
            reject(new Error("Empty response"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const getTransactionDetails = (token) => {
    const promise = new Promise((resolve, reject) => {
      axiosInstance
        .get(`/asset/buy/${productDetails?.id}`, {
          headers: { Authorization: token },
        })
        .then((res) => {
          if (res) {
            resolve(res);
          } else {
            reject(new Error("Empty response"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  };

  const onCloseWidgetwert = (codeExecutedTillPayment_status) => {
    PopUpAlert(
      "Info",
      codeExecutedTillPayment_status === "failedTranscation"
        ? "Payment Declined, Verify card details. If issue persists, contact your bank and reach out to our support team for assistance."
        : "Transaction in progress, This process may take some time to complete  .",

      codeExecutedTillPayment_status === "failedTranscation"
        ? "Failed"
        : "success"
    ).then(() => {
      if (saleType !== "auction") window.location.href = "/dashboard?tab=3";
      else window.location.href = "/dashboard?tab=2&atab=0";
    });
  };

  const wertCloseAfterPayement = () => {
    let popupMsg =
      saleType == "fixed"
        ? "Payment initiated; the token will appear in your account upon successful processing."
        : "Payment initiated; the bid will get reflected in active bids tab on successful processing.";
    PopUpAlert("Info", popupMsg, "success").then(() => {
      if (saleType !== "auction") window.location.href = "/dashboard?tab=3";
      else window.location.href = "/dashboard?tab=2&atab=0";
    });
  };

  const wertReachedPaymentStatus = (data) => {
    let popupMsg =
      saleType == "fixed"
        ? "Payment was successful, token will be reflected in  account in sometime."
        : "Payment was successful, bid will get reflected in actyourive bids tab in sometime.";
    PopUpAlert("Info", popupMsg, "success").then(() => {
      if (saleType !== "auction") window.location.href = "/dashboard?tab=3";
      else window.location.href = "/dashboard?tab=2&atab=0";
    });
  };

  const wertCloseBeforeTransaction = () => {
    let popupMsg = "Your transaction is incomplete. Please try again..";
    PopUpAlert("Alert", popupMsg, "error").then(() => {
      window.location.reload();
    });
  };

  const closeSuccessModal = () => {
    if (saleType === "fixed") {
      window.location.href = "/dashboard?tab=3";
    } else {
      window.location.href = "/dashboard?tab=2&atab=0";
    }
  };

  const runWertWidget = (token) => {
    // if (window.ethereum) {

    let [YEAR, MONTH, DATE] = profile.dob.split("-");

    let wertProfileDetails = {
      full_name: profile.firstName + " " + profile?.lastName,
      email: profile?.email,
      date_of_birth:
        profile?.country === "US"
          ? `${MONTH}/${DATE}/${YEAR}`
          : `${DATE}/${MONTH}/${YEAR}`,
      phone: "+" + profile?.mobileNumber,
      country_of_residence: profile.country,
    };

    try {
      // console.log(" Inside window etherium condition")

      (async () => {
        let openWidget = true;
        const web3 = await getWeb3Provider();

        let transactionDetails = await getTransactionDetails(token);

        //console.log("wert transactionDetails", transactionDetails);

        transactionDetails = transactionDetails?.data?.result;
        let userSignatureResult = await handleGetSignature(
          transactionDetails,
          token
        );
        let userSignature = userSignatureResult?.data?.result?.result;
        let signatureDetails = userSignatureResult?.data?.result?.payload;
        //console.log("this is user signature", userSignature);

        //console.log("this is user signature", signatureDetails);

        // const maticPriceBN = new BigNumber(Number(
        //   saleType === "auction"
        //     ? bidAmountCrypto
        //     : transactionDetails?.maticPrice
        // ));
        // const fiatPriceBN = new BigNumber(Number(
        //   saleType === "auction"
        //     ? bidAmountFiatWEI
        //     : transactionDetails?.regularPrice
        // ));

        // const maticPriceHex = "0x" + maticPriceBN.toString(16);
        // const fiatPriceHex = "0x" + fiatPriceBN.toString(16);

        // console.log(
        //   "wert.input_data_sc - before sc_inp amount maticPrice, regularPrice, maticPriceHex, fiatPriceHex",
        //   transactionDetails?.maticPrice,
        //   transactionDetails?.regularPrice
        //   // maticPriceHex,
        //   // fiatPriceHex
        // );

        // console.log(
        //   "----------> wert DAta----------->",
        //   signatureDetails.creators
        // );

        // console.log(
        //   "account info",
        //   userAccountDetails?.account?.[0],
        //   "tokenId",
        //   transactionDetails.tokenId,
        //   "price",
        //   signatureDetails.price,
        //   "array",
        //   [
        //     signatureDetails.royaltyReceiver,
        //     signatureDetails.royaltyPercentage,
        //     signatureDetails.ipfsHash,
        //   ],
        //   "creators",
        //   userSignatureResult?.data?.result?.creators,
        //   "signature",
        //   userSignature
        // );
        let sc_input_data_fixed = null;
        let sc_input_data_auction = null;
        if (saleType != "auction") {
          sc_input_data_fixed = web3.eth.abi.encodeFunctionCall(
            buyWithUsdcPayment,
            [
              userAccountDetails?.account?.[0], // Change needed - Take receiver address of user // userprofile
              transactionDetails?.tokenId, // Token ID from API
              signatureDetails?.price,
              [
                signatureDetails?.royaltyReceiver,
                signatureDetails?.royaltyPercentage,
                signatureDetails?.ipfsHash,
              ],
              userSignatureResult?.data?.result?.creators,
              userSignature, // signature.-- Get signature from Blockchain API.. Need to done from FE
            ]
          );
        } else {
          // console.log(
          //   "signatureDetails",
          //   signatureDetails,
          //   "user signnn",
          //   userSignature
          // );
          sc_input_data_auction = web3.eth.abi.encodeFunctionCall(
            bidWithUsdcPayment,
            [
              userAccountDetails?.account?.[0], // Change needed - Take receiver address of user // userprofile
              transactionDetails?.tokenId, // Token ID from API
              signatureDetails.amount,
              userSignature,
            ]
          );
        }

        const privateKey = process.env.REACT_APP_WERT_KEY; // To use wert - private key
        // console.log("this is the saletype", saleType);
        const sc_input_data =
          saleType === "auction" ? sc_input_data_auction : sc_input_data_fixed;
        //console.log("sc_input_data : ", sc_input_data);
        const signedData = signSmartContractData(
          {
            address: userAccountDetails?.account?.[0], // / Change needed - Take receiver address of user // userprofile
            commodity: ["PRODUCTION"].includes(SERVER_ENVIORNMENT)
              ? "USDCc"
              : "TT", // Currently Test token , In prod it will change to USDC
            commodity_amount:
              saleType === "auction"
                ? bidAmountFiat
                : productDetails?.isOnSell
                ? productDetails?.resellPrice
                : productDetails?.regularPrice, // the USDC amount that should be send to the contract method in decimal format .
            network: ["PRODUCTION"].includes(SERVER_ENVIORNMENT)
              ? "polygon"
              : "bsc",
            sc_address: contractDetails?.ASLY_MARKETPLACE_ADDRESS, // your SC address - take from contract abi - smart contract address
            sc_input_data,
          },
          privateKey
        );

        //Backend API call to create order
        // if (saleType !== "auction")
        const wertResult = await processWertResult({
          click_id: wertOtherWidgetOptions?.click_id,
        });
        if (!wertResult.returnValue) {
          openWidget = false;
        }
        // .then((data)=>{
        //   console.log("data  processWertResult ",data)
        //   if (!data) openWidget= false
        // });

        // const otherWidgetOptions = {
        //   partner_id: "01GSX0Q2W016CVJFDHQX2MBENS", // your partner id
        //   container_id: "widget",
        //   click_id: uuidv4(), // unique id of purhase in your system
        //   origin: "https://sandbox.wert.io", // this option needed only in sandbox
        //   width: 1400,
        //   height: 600,
        // };
        const nftOptions = {
          extra: {
            item_info: {
              author: `${transactionDetails?.creator?.firstName} ${transactionDetails?.creator?.lastName}`,
              image_url: transactionDetails?.mediaPreviewUrl,
              name: transactionDetails?.name,
              // name: transactionDetails?.name+ ' #'+transactionDetails?.currentEdition+'/'+transactionDetails?.totalEdition,
              seller: "lii",
            },
          },
        };

        // console.log("==== Wert Widget Options signedData ====", signedData);
        // console.log(
        //   "==== Wert Widget Options wertOtherWidgetOptions ====",
        //   wertOtherWidgetOptions
        // );
        // console.log("==== Wert Widget Options nftOptions ====", nftOptions);

        // console.log("Wert Widget Options signedData", signedData);
        // console.log(
        //   "Wert Widget Options wertOtherWidgetOptions",
        //   wertOtherWidgetOptions
        // );
        // console.log("Wert Widget Options nftOptions", nftOptions);
        // console.log("wert sc data inputs", sc_input_data);
        const wertWidget = new WertWidget({
          ...signedData,
          ...wertOtherWidgetOptions,
          ...wertProfileDetails,
          ...nftOptions,
          listeners: {
            "payment-status": (data) => {
              codeExecutedTillPayment_status = "afterPayement";
              if (saleType == "fixed" && data.status === "pending") {
                updateFixedOrderStatus(
                  transactionDetails.id,
                  wertResult.returnObj.id,
                  wertResult.returnObj.transactionId
                ); // assetId, orderId, transactionId data passed in this order
              }
              if (data.status === "success") {
                codeExecutedTillPayment_status = "afterPaymentSuccess";

                // *** for wert payment we don't depend on data.status === "success", we get webhook call from wert ***
                // if (saleType === "auction") { processWertResult(data) }
                // else handleWertResult(data.tx_id)

                if (saleType !== "auction") handleWertResult(data.tx_id);
                else
                  wertHookSubstitueAuction(
                    wertOtherWidgetOptions?.click_id,
                    data.transaction_id
                  );
                // added temporyly for demo purpose.. Remove this later to make it work with webhook
                // handleWertResult(data);

                const removeIframeByMatchingUrl = (url) => {
                  const iframes = document.querySelectorAll("iframe");

                  for (const iframe of iframes) {
                    if (iframe.src.includes(url)) {
                      iframe.remove();
                    }
                  }
                };
                removeIframeByMatchingUrl("sandbox.wert.io");
                wertWidget.destroy();

                if (codeExecutedTillPayment_status == "afterPaymentSuccess") {
                  callSetSuccessShow(saleType);
                } else
                  PopUpAlert(
                    "Processing..",
                    "Oops! Something went wrong you will be redirected to home page.",
                    "info"
                  ).then(() => {
                    window.location.href = "/";
                  });
              } else if (data.status === "failed") {
                codeExecutedTillPayment_status = "failedTranscation";
              }
            },
            close: (data) => {
              if (!wertProcessed && saleType == "fixed") {
                processWertResult(data);
              }

              if (codeExecutedTillPayment_status === "failedTranscation") {
                PopUpAlert(
                  "Failed",
                  "Payment Declined, Verify card details. If issue persists, contact your bank and reach out to our support team for assistance.",
                  "error"
                ).then(() => {
                  window.location.reload();
                });
              }

              if (codeExecutedTillPayment_status === "afterPayement") {
                wertCloseAfterPayement();
              }
              if (codeExecutedTillPayment_status === "afterPaymentSuccess") {
                wertReachedPaymentStatus(data);
              }
              if (codeExecutedTillPayment_status === "") {
                wertCloseBeforeTransaction();
              }
              if (codeExecutedTillPayment_status === null) {
                onCloseWidgetwert(codeExecutedTillPayment_status);
              }
            },
          },
        });

        // processWertResult();
        if (openWidget) {
          wertWidget.mount();
        }
      })();
    } catch (error) {
      console.log("error in try ,", error);
    }
    // } else  {

    //   console.log(" window etherium condition failure .")
    // }
  };

  return <div id="widget"></div>;
};

export default WertWidgetDiv;
