import WertWidget from "@wert-io/widget-initializer";
import { useEffect, useState } from "react";
import { wertTopUpWidgetOptions } from "../../utils/config";
import { useSelector } from "react-redux";
// import TransparentModal from "../../components/TransparentModal";
import { axiosInstance } from "../../utils/API";
import { PopUpAlert } from "../../controller/utils";
const TopUpWalletWert = ({
  setOpenTopUpModal,
  onCloseWidget,
  walletId,
  wallet,
}) => {
  const [mountTransparent, setMountTransparent] = useState(false);
  const profile = useSelector((state) => state?.profileReducer);

  const createTopupHistory = async (wertData) => {
    try {
      console.log("wert data", wertData);
      let config = {
        headers: {
          Authorization: localStorage.getItem("userToken"),
        },
      };
      let click_id = wertData?.click_id;
      if (!click_id) {
        click_id = wertTopUpWidgetOptions.click_id;
      }
      let data = await axiosInstance.post(
        "/topuphistory",
        {
          click_id: click_id,
          transactionType: "topup",
        },
        config
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const wertCloseAfterPayement = () => {
    PopUpAlert(
      "Info",
      "Payment got initiated, amount will be reflected in your wallet upon successful transaction.",
      "success"
    ).then(() => {
      window.location.href = "/dashboard?tab=12";
    });
  };

  const wertReachedPaymentStatus = () => {
    PopUpAlert(
      "Info",
      "Payment was successful, amount will be reflected in your wallet.",
      "success"
    ).then(() => {
      window.location.href = "/dashboard?tab=12";
    });
  };

  const wertCloseBeforeTransaction = () => {
    let popupMsg = "Your transaction is incomplete.";
    PopUpAlert("Alert", popupMsg, "error").then(() => {
      window.location.reload();
    });
  };

  const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

  const wertCommodities = ["PRODUCTION"].includes(SERVER_ENVIORNMENT)
    ? {
        commodities: JSON.stringify([
          wallet == "USDC"
            ? { commodity: "USDCc", network: "polygon" }
            : { commodity: "MATIC", network: "polygon" },
        ]),
        commodity: "MATIC",
        network: "polygon",
      }
    : ["UAT"].includes(SERVER_ENVIORNMENT)
    ? {
        commodities: JSON.stringify([
          wallet == "USDC"
            ? { commodity: "TT", network: "mumbai" }
            : { commodity: "MATIC", network: "mumbai" },
        ]),
      }
    : {
        commodities: JSON.stringify([
          wallet == "USDC"
            ? { commodity: "TT", network: "bsc" }
            : { commodity: "BNB", network: "bsc" },
        ]),
      };

  const commodities = [];

  const modifiedWertTopUpWidgetOptions = {
    ...wertTopUpWidgetOptions,
    // Edit or add key-value pairs as needed
    commodities: wertCommodities.commodities,
    // ... add more modifications as needed
  };

  const runWertTopUpWidget = () => {
    let [YEAR, MONTH, DATE] = profile.dob.split("-");

    let wertProfileDetails = {
      full_name: profile.firstName + " " + profile?.lastName,
      email: profile?.email,
      date_of_birth:
        profile?.country === "US"
          ? `${MONTH}/${DATE}/${YEAR}`
          : `${DATE}/${MONTH}/${YEAR}`,
      phone: "+" + profile?.mobileNumber,
      country_of_residence: profile.country,
    };

    (async () => {
      let checkPoint = "";
      const wertWidget = new WertWidget({
        ...modifiedWertTopUpWidgetOptions,
        ...wertProfileDetails,
        wertCommodities,
        address: walletId,
        listeners: {
          "payment-status": (data) => {
            checkPoint = "afterPayement";
            createTopupHistory(data);
            console.log("Payment status is::::", data);
            if (data.status === "success") {
              checkPoint = "afterPaymentSuccess";
            }
            else if (data.status === "failed") {
              checkPoint = "failedTranscation";
            }
          },

          close: (data) => {
            createTopupHistory(data);
            setOpenTopUpModal(false);
            if (checkPoint === "failedTranscation") {
              PopUpAlert(
                "Failed",
                "Payment Declined, Verify card details. If issue persists, contact your bank and reach out to our support team for assistance.",
                "error"
              ).then(() => {
                window.location.reload();
              });
            }
            else if (checkPoint === "afterPayement") {
              // console.log("WERT afterPayement");
              wertCloseAfterPayement();
            } else if (checkPoint === "afterPaymentSuccess") {
              // console.log("WERT afterPaymentSuccess");
              wertReachedPaymentStatus();
            } else if (checkPoint === "") {
              // console.log("WERT wertCloseBeforeTransaction");
              wertCloseBeforeTransaction();
            } else {
              // console.log("WERT else");
              onCloseWidget();
            }

            // You can perform any necessary actions when the widget is closing
            // For example, hide the widget container or perform cleanup
          },
        },
      });

      // processWertResult();
      wertWidget.mount();
      setMountTransparent(false);
    })();
  };

  useEffect(() => {
    runWertTopUpWidget();

    console.log("adding the clickId");
  }, []);

  // if (setMountTransparent) {
  //   return <TransparentModal />
  // }

  return <div id="widget"></div>;
};

export default TopUpWalletWert;
