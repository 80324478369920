import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLBlockChain = baseURL;//.substring(0, baseURL.length - 8);

console.log("baseURLBlockChain", baseURLBlockChain);

export const axiosInstance = axios.create({
  baseURL,
  responseType: "json",
});

export const axiosInstanceBlockChain = axios.create({
  baseURL: baseURLBlockChain,
  responseType: "json",
});

export default axios.create({
  baseURL: baseURL,
  responseType: "json",
});
