import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Withdraw.module.sass";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddWireAccount from "../Withdraw/AddWireAccount";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { PopUpAlert, checkNetwork, getConfig, getGasPrice, getWeb3Provider } from "../../../controller/utils";
import { axiosInstance } from "../../../utils/API";
import Loader from "../../Item/Loader.jsx";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { erc20_contract_abi, getUsdcBalanceFromAccount } from "../../../utils/balance.js";
import { setAmount } from "../../../redux/usdcSlice";
import { useHistory } from "react-router-dom";

  // call this to Enable
  function enableScroll() {
    document.body.style.overflow = "scroll";
  }
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  MuiAppBarRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    border: "none",
    backgroundColor: "#ffffff",
    width: "60%",
    ["@media (max-width:1366px)"]: {
      width: "68%",
    },
    ["@media (max-width:640px)"]: {
      width: "88% !important",
    },
    boxShadow: "0px 0.563rem 0.938rem rgb(0 0 0 / 8%)",
    borderRadius: "0.625rem",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },

  MuiTabRoot: {
    padding: "0.375rem 0.75rem",
    overflow: "hidden",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "center",
    flexShrink: "0",
    fontWeight: "500",
    whiteSpace: "normal",
    minWidth: "0px",
    color: "#BFBFBF",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontFamily: "'Poppins'",
    lineHeight: "1.5rem",
    textTransform: "none !important",
    height: "auto",
  },

  tabs: {
    "& MuiPaper-root.MuiAppBar-root": {
      width: "48%",
      border: "0.063rem solid #fff",
      borderRadius: "0.313rem",
    },

    "& .MuiTabs-flexContainer": {
      background: "#FFFFFF",
      color: "#000000",
      display: "flex",
      padding: "0.375rem 0px 0px 0px",
      justifyContent: "flex-start",
      boxShadow: "0px 0.563rem 0.938rem rgb(0 0 0 / 8%)",
      border: "0.063rem solid #ffffff",
      borderRadius: "0.375rem",
      width: "100%",
    },

    "& .MuiTabs-fixed": {
      // background: "#FFFFFF",
      color: "#000000",
      display: "flex",
      justifyContent: "space-evenly",
      // boxShadow: "0px 0.563rem 0.938rem rgb(0 0 0 / 8%)",
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      fontFamily: "'Poppins'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "0.75rem",
      lineHeight: "1.5rem",
      textTransform: "capitalize",
      color: "black",
      width: "50%"
    },
    "& .MuiTabs-indicator": {
      display: "none",
      //backgroundColor: "orange"
    },
    "& .Mui-selected": {
      background: "#000000",
      borderRadius: "0.625rem",
      color: "white!important"
      // textDecoration: "underline"
    },
    boxShadow: "none",
    width: "100%",
  },
  tabTwo: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "green",
    },
  },
  tabThree: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "yellow",
    },
  },
  tabPanel: {
    backgroundColor: "rgba(1,1,1,0.1)",
    paddingTop: 12,
  },
}));

const WalletInput = ({ handleInputChange }) => {
  const [cryptoWithdraw, setCryptoWithdraw] = useState({
    active: false,
    cryptoAccountNo: null,
  });

  useEffect(() => {
    handleInputChange(cryptoWithdraw);
  }, [cryptoWithdraw]);

  return (
    <input
      type="text"
      className={styles.formControl}
      key="walletId"
      name="cryptoAccountNo"
      value={cryptoWithdraw.cryptoAccountNo}
      onChange={(e) =>
        setCryptoWithdraw((prevState) => ({
          ...prevState,
          [e?.target?.name]: e?.target?.value,
        }))
      }
    />
  );
};

export default function Withdraw({ closePopup, refreshPage, setRefreshPage }) {
  const classes = useStyles();
  const [tabSelected, setTabSelected] = React.useState(0);
  const [fields, setFields] = useState({
    depositAmount: 0,
  });
  const [cryptoWithdraw, setCryptoWithdraw] = useState({
    active: false,
    cryptoAccountNo: null,
  });
  const [screenWidth] = useWindowSize();
  const [wireID, setWireID] = useState(null);
  const [visible, setVisible] = useState(false);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [enableLoader, setEnableLoader] = useState(false);
  const [tabIndex, setTabIndex] = useState(1)
  let circleBalance = useSelector((state) => state.circlePayment.balance);
  let walletId = useSelector((state) => state.circlePayment.walletId);
  const usdcWalletBalance = useSelector((state) => state.usdc.amount)
  const dispatch = useDispatch();

  const handleChangeTab = (event, newValue) => {
    console.log("newValue", newValue)
    setTabSelected(newValue);
  };
  const history = useHistory();

  useEffect(() => {
    getWireAccountList();

    console.log(screenWidth);
  }, [refreshPage]);

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFields({ ...fields, [name]: value });
  };

  const getWireAccountList = async () => {
    const config = getConfig();
    await axiosInstance
      .get(`/user/banks/wires`, config)
      .then((res) => {
        console.log("Show the result ", res?.data?.result?.cards);
        if (res?.data?.result?.cards && res?.data?.result?.cards?.length > 0) {
          setBankAccountList(res?.data?.result?.cards);
        } else {
          setBankAccountList([]);
        }
      })
      .catch((err) => {
        PopUpAlert("Alert", err.response.data.message, "warning");
      });
  };

  const submitHandle = async () => {
    setEnableLoader(true);
    if (Number(fields?.depositAmount) === 0 || !fields?.depositAmount) {
      PopUpAlert("Alert", "Enter a valid amount.", "error").then((res) => {
        // history.push("/Circlepayment");
      });
      setEnableLoader(false);
      return false;
    }
    // if (validationField()) {
    // if (1 == 1) {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };

    let body = {
      source: {
        type: "wallet",
        id: walletId,
      },
      destination: {
        type: "wire",
        id: wireID,
      },
      amount: {
        amount: fields?.depositAmount,
        currency: "USD",
      },
      metadata: {
        beneficiaryEmail: "satoshi@circle.com",
      },
    };

    await axiosInstance
      .post(`user/payout/withdraw`, body, config)
      .then((res) => {
        if (res) {
          setEnableLoader(false);
          closePopup();
          setRefreshPage(!refreshPage);
          PopUpAlert(
            "Success",
            "Amount withdrawal request successfully processed",
            "success"
          ).then((res) => {
            // history.push("/Circlepayment");
          });
        }
      })
      .catch((err) => {
        PopUpAlert(
          "Alert",
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message,
          "error"
        ).then(() => setEnableLoader(false));
      });
    // }
  };

  const submitCryptoDespoist = async () => {
    setEnableLoader(true);
    if (Number(fields?.depositAmount) === 0 || !fields?.depositAmount) {
      PopUpAlert("Alert", "Enter a valid amount.", "error").then((res) => {
        setEnableLoader(false);
        // history.push("/Circlepayment");
      });
      return false;
    }
    if (!cryptoWithdraw?.cryptoAccountNo) {
      PopUpAlert("Alert", "Please enter a valid wallet id.", "error").then((res) => {
        setEnableLoader(false);
      });
      return false;
    }
    // if (validationField()) {
    // if (1 == 1) {
    // let config = {
    //   headers: {
    //     Authorization: localStorage.getItem("userToken"),
    //   },
    // };

    // let body = {
    //   source: {
    //     type: "wallet",
    //     id: walletId,
    //   },
    //   destination: {
    //     type: "blockchain",
    //     chain: "ETH",
    //     address: cryptoWithdraw?.cryptoAccountNo,
    //   },
    //   amount: {
    //     amount: fields?.depositAmount,
    //     currency: "USD",
    //   },
    // };

    const Web3 = require('web3');
    const erc20ContractAddress = process.env.REACT_APP_USDC_CONTRACT_ADDRESS || '0x879bad9DcD7e7f79B598a632103984FC090DA00D';

    //withdraw usdc


    let amount = fields?.depositAmount
    let destinationAccount = cryptoWithdraw?.cryptoAccountNo

    try {
      let web3 = await getWeb3Provider();

      const chainId = await web3.eth.getChainId();
      const networkVerify = await checkNetwork(chainId);
      if (!networkVerify) {
        //connected to wrong network
        console.log("conntected to wrong", networkVerify)
        return false;
      }
      // based on the wallet used create web3 instance with the provider
      // creating smartcontract instance
      const usdcContract = await new web3.eth.Contract(erc20_contract_abi, erc20ContractAddress);

      // account is an array of available accounts in connected wallet
      const account = await web3.eth.getAccounts();

      try {
        let amountInWei = web3.utils.toWei(amount.toString(), "mwei");

        console.log("emaount in wei", amountInWei)

        //creating method object
        const usdcTransferMethod = usdcContract.methods.transfer(destinationAccount, amountInWei);

        let gasEstimated = null;
        let txObject = null;

        try {
          const gasPrice = await getGasPrice(web3);

          //creating transaction object
          txObject = {
            from: account[0],
            value: 0,
            gasPrice: gasPrice,
          };
          //estimating transaction fee for this function call
          gasEstimated = await web3.eth.estimateGas({
            to: erc20ContractAddress,
            data: usdcTransferMethod.encodeABI(),
            ...txObject,
          });
        } catch (err) {
          console.log("errro", err)
          setEnableLoader(false);
          setRefreshPage(!refreshPage);
          let errText ='Transaction Failed. Please try again'

          if(err.toString()?.includes("insufficient")){
            errText = 'Transaction Failed. You should have enough Matic balance for gas fee'
          }
          PopUpAlert(
            "Error",
            `${errText}`,
            "error",
            true
          ).then((res) => {
            enableScroll();
            // if(isDismissed == )
          if(!res.isDismissed)
            history.push("/dashboard?tab=12");
          });

          return err;
        }

        //sending transaction through metamask
        usdcTransferMethod.send({ ...txObject, gas: gasEstimated }).then(function (result) {
          if (result.status == true) {
            console.log("success!" + result.transactionHash);
            updateWithdrawPayment({
              status: 'success',
              txnHash: result.transactionHash
            })
            const polygon =
              process.env.NODE_ENV === "development"
                ? "https://mumbai.polygonscan.com/tx/"
                : "https://polygonscan.com/tx/";

            setEnableLoader(false);
            setRefreshPage(!refreshPage);

            closePopup();
            PopUpAlert(
              "Success",
              `Amount withdrawal request successfully processed. Track the transaction by navigating following link ${polygon}${result.transactionHash}`,
              "success"
            )



          } else if (result.status == false) {
            // blockchain transaction status is failed.
            setEnableLoader(false);
            setRefreshPage(!refreshPage);
            closePopup();
            PopUpAlert(
              "Error",
              `Amount withdrawal failed. Please try again`,
              "error"
            )
            let usdcBalance = getUsdcBalanceFromAccount(account[0]);
            dispatch(setAmount(usdcBalance));
          }
        }).catch((error) => {
          console.log("error", error);
          setEnableLoader(false);
          setRefreshPage(!refreshPage);
          closePopup();
          PopUpAlert(
            "Error",
            `Amount withdrawal failed. Please try again`,
            "error"
          )
        });
      } catch (err) {
        console.log(err);
        if (err.code === 4001) {
          console.log("Oops!", "Install Metamsk!!", "error");
          setEnableLoader(false);
          setRefreshPage(!refreshPage);
          closePopup();
          PopUpAlert(
            "Error",
            `Metamask Not found. please install metamask`,
            "error"
          )
          return "Install Metamask"
        } else {
          console.log("Oops!", "Transaction has been failed please try again!", err);
          setEnableLoader(false);
          setRefreshPage(!refreshPage);
          closePopup();
          PopUpAlert(
            "Error",
            `Amount withdrawal failed. Please try again`,
            "error"
          )
        }

      }


    } catch (error) {
      console.log("Oops!", "Transaction has been failed please try again!", error);
      setEnableLoader(false);
      setRefreshPage(!refreshPage);
      closePopup();
      PopUpAlert(
        "Error",
        `Amount withdrawal failed. Please try again`,
        "error"
      )

    }

    const updateWithdrawPayment = async (withdrawDetails) => {

      try {
        let config = {
          headers: {
            Authorization: localStorage.getItem("userToken"),
          },
        };
        await axiosInstance.post('/topuphistory', {
          "transactionType": "withdraw",
          "status": withdrawDetails.status,
          "quote": "TT",
          "baseAmount": fields?.depositAmount,
          "receiverAddress": cryptoWithdraw?.cryptoAccountNo,
          "transactionHash": withdrawDetails.txnHash
        }, config)

      } catch (err) {


      }

    }




  };
  // //console.log('data
  // Close Add Wire/Band account modal
  const modalClose = () => {
    // getListOfCard();
    setVisible(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ width: "100%" }}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={styles.depoistPopupContainer}>
      <div className={cn("transferWrapper", styles.transferWrapper)}>
        <div className={styles.TransferModal} style={{ color: "#000000" }}>
          <div style={{ overflow: "auto", maxHeight: "84vh", padding: "0 1.25rem" }}>
            <div className={styles.TransferModalHeader}>
              <span className={styles.transferModalLabel}>Withdraw USDC</span>
              <span
                className={styles.transferModalCloseButton}
                onClick={() => closePopup()}
              >
                x
              </span>
            </div>
            {/* <span>Testing</span>
          <input
            type="text"
            className={styles.formControl}
            value={cryptoWithdraw.cryptoAccountNo}
            onChange={(e) =>
              setCryptoWithdraw({
                ...cryptoWithdraw,
                [e?.target?.name]: e?.target?.value,
              })
            }
          ></input> */}

            <AddWireAccount
              visible={visible}
              closeModal={() => modalClose()}
              setRefreshPage={setRefreshPage}
              refreshPage={refreshPage}
            />
            <div className={styles.withdrawContainer}>
              {/* <div className={styles.transferModalDescription}>
                Select currency from your wallet
              </div> */}
              <div
                className={styles.balanceDisplayContainer}
              // style={{
              //   padding: screenWidth < 1400 ? "0px 4rem" : "0px 144px",
              // }}
              >
                {/* <div className={styles.balanceDisplayWrapper}>
                  <span className={styles.balanceDisplayLabel}>
                    Your balance
                  </span>
                  <div className={styles.balanceDisplay}>
                    <div className={styles.circleBalance}> */}
                      {/* <img
                        src={"/images/dollar.png"}
                        width="1.125rem"
                        alt={"dollar"}
                      /> */}
                      {/* <div className={styles.font600}>
                        {usdcWalletBalance} USDC
                      </div>
                    </div> */}
                    {/* <div className={styles.cryptoBalance}>
                    <img src={"/images/etherium-circle.jpg"} width="1.125rem" />
                    <div className={styles.font600}>-- ETH</div>
                  </div> */}
                  {/* </div>
                </div> */}
              </div>
              {tabSelected == 0 ? (
                  <div style={{ width: "100%" }}>
                    {/* style={{ width: "48%" }} */}
                    <div className={styles.formGroup}>
                      <form>
                        <label className={styles.withDrawLabel}>
                          Enter wallet address<span className="red-star">*</span>
                        </label>
                        {/* <input
                    type="text"
                    value={cryptoWithdraw.cryptoAccountNo}
                    onChange={(e) =>
                      setCryptoWithdraw({
                        ...cryptoWithdraw,
                        [e?.target?.name]: e?.target?.value,
                      })
                    }
                  ></input> */}
                        {/* <WalletInput handleInputChange={(inputData) => setCryptoWithdraw({
                        ...cryptoWithdraw,
                        [e?.target?.name]: e?.target?.value,
                      })/> */}
                        <input
                          type="text"
                          className={styles.formControl}
                          key="walletId"
                          name="cryptoAccountNo"
                          value={cryptoWithdraw.cryptoAccountNo}
                          onChange={(e) =>
                            setCryptoWithdraw((prevState) => ({
                              ...prevState,
                              [e?.target?.name]: e?.target?.value,
                            }))
                          }
                        />
                      </form>
                    </div>
                  </div>
                ) : null}
              <div>
              <div className={styles.transferModalDescription}>
                Network
              </div>
              <input
                  type="text"
                  className={styles.depositAmount}
                  name="Currency"
                  disabled
                  value={'Polygon(USDC)'}
                />
              </div>
              <div
                className={`${styles.flexColumnCenter} ${styles.marginTop3p} ${styles.paddingInputWrapper}`}
              >
                {/* <div  */}
                {/* // style={{ width: screenWidth < 1400 ? "114%" : "70%" }} */}
                {/* > */}
                <div className={styles.withDrawLabel}>
                  Enter the amount to withdraw <span className="red-star">*</span>
                </div>
                <input
                  type="text"
                  className={styles.depositAmount}
                  name="depositAmount"
                  value={fields?.depositAmount ? fields?.depositAmount : null}
                  onChange={(e) => handleChange(e)}
                  placeholder={`${usdcWalletBalance} USDC`}
                />
                {/* </div> */}
                {/* {error?.cvv && (
                <label className={styles.error}>{error?.cvv}</label>
              )} */}
              </div>
              <div
                className={`${styles.flexColumnCenter} ${styles.marginTop3p}`}
              >
                {/* <div className={styles.withDrawModalLabel}>Withdraw to</div> */}
                {/* <AppBar
                  position="static"
                  color="default"
                  classes={{
                    root: classes.MuiAppBarRoot,
                  }}
                >
                  <Tabs
                    value={tabSelected}
                    onChange={handleChangeTab}
                    className={classes.tabs}
                  >
                    <Tab
                      label="Crypto wallet"
                      {...a11yProps(0)}
                      // disabled={true}
                      classes={{
                        root: classes.MuiTabRoot,
                      }}
                      disableFocusRipple={true}
                    />
                    {/* <Tab
                      label="Bank account"
                      {...a11yProps(1)}
                      classes={{
                        root: classes.MuiTabRoot,
                      }}
                    /> */}
                {/* </Tabs>
                </AppBar> */}
                {/* <TabPanel value={tabSelected} index={0} style={{ width: "80%" }}> */}

                <TabPanel
                  value={tabSelected}
                  index={1}
                  className="tabWithdraw"
                // style={{ width: screenWidth < 1400 ? "138%" : "100%" }}
                >
                  <div className={styles.withDrawWirePaymentAccount}>
                    <div className={styles.withDrawWireMethod}>
                      <label className={styles.choosePaymentMethodLabel}>
                        Choose payment method <span className="red-star">*</span>
                      </label>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel
                          disableAnimation={true}
                          shrink={false}
                        ></InputLabel>
                        <Select
                          native
                          name="cryptAccountNo"
                          value={wireID}
                          onChange={(e) => setWireID(e?.target?.value)}
                          label="Age"
                          inputProps={{
                            name: "age",
                            id: "outlined-age-native-simple",
                          }}
                          InputLabelProps={{ shrink: false }}
                        >
                          <option aria-label="None" value="" selected />
                          {bankAccountList &&
                            bankAccountList?.length > 0 &&
                            bankAccountList.map((item, index) => {
                              return (
                                <option value={item?.bankWireId} key={index}>
                                  {item?.bankName}
                                </option>
                              );
                            })}

                          {/* <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option> */}
                        </Select>
                      </FormControl>
                      {/* <select
                    type="text"
                    className={styles.formControl}
                    name="accountno"
                    shrink={false}
                    // value={fields?.cvv}
                    // onChange={(e) => handleChange(e)}
                  /> */}
                      {/* {error?.cvv && (
                <label className={styles.error}>{error?.cvv}</label>
              )} */}
                    </div>
                  </div>
                  <div
                    className={styles.flexRowCenter}
                    style={{
                      marginTop: "0.625rem",
                      // padding: screenWidth < 640 ? "0px 3.875rem" : "",
                    }}
                  >
                    <span>
                      Don’t have an account?{" "}
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={() => setVisible(true)}
                      >
                        Add an account
                      </b>
                    </span>
                    {/* <div>

										<div className={styles.flexColumnCenter}>Or</div>
										<button
											type="button"
											className={styles.addWireAccount}
											onClick={() => setVisible(true)}
										>
											Add Account
										</button>
									</div> */}
                  </div>
                </TabPanel>
              </div>

              {/*<div className={styles.totalAmountWrapper}>*/}
              {/*  <div*/}
              {/*    className={styles.totalAmount}*/}
              {/*  //   style={{ width: screenWidth < 1400 ? "67%" : "48%" }}*/}
              {/*  >*/}
              {/*    /!* <div className={styles.totalAmountBlock}>*/}
              {/*    <span className={styles.totalAmountBlockLabel1}>*/}
              {/*      Transaction Fee*/}
              {/*    </span>*/}
              {/*    <div className={styles.totalAmountValue1}>10 USD</div>*/}
              {/*  </div> *!/*/}
              {/*    /!*<div className="lineSplitter"></div>*!/*/}
              {/*    /!*<div className={styles.totalAmountBlock}>*!/*/}
              {/*    /!*  <span className={styles.totalAmountBlockLabel2}>*!/*/}
              {/*    /!*    TOTAL AMOUNT*!/*/}
              {/*    /!*  </span>*!/*/}
              {/*    /!*  <span className={styles.totalAmountValue2}>*!/*/}
              {/*    /!*    {Number(fields?.depositAmount)} USDC*!/*/}
              {/*    /!*  </span>*!/*/}
              {/*    /!*</div>*!/*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div
                className={styles.formGroup}
                style={{
                  display: "flex",
                  justifyContent: screenWidth < 640 ? "flex-start" : "center",
                  cursor: "pointer",
                  marginTop: "1.125rem",
                  // padding: screenWidth < 640 ? "0px 1.25rem" : "",
                  // width: screenWidth < 640 ? "137%" : "100%",
                }}
              >
                <button
                  type="button"
                  className={styles.withDrawBtn}
                  // style={{ width: screenWidth < 1400 ? "65%" : "47%" }}
                  onClick={(e) =>
                    tabSelected == 0
                      ? submitCryptoDespoist(e)
                      : submitHandle(e)
                  }
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader active={enableLoader} type={"no"} />
    </div>
  );
}
