import styles from "./dashcardview.module.sass";
import { axiosInstance } from "../../utils/API";
import { useEffect, useState, useLayoutEffect } from "react";
import Card from "../../components/Card";
import cn from "classnames";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    border: "0.063rem solid white",
    borderRadius: "0.313rem",
    padding: "0.375rem 0.5rem",
    minWidth: "7.5rem",
    height: "0.313rem",
    boxShadow: 'none',
    "&:focus": {
      backgroundColor: "transparent !important",
      border: "0.063rem solid white",
      boxShadow: "none", // Remove box shadow on focus
    },
  },
  icon: {
    fill: "white",
  },
  label: {
    transform: "translate(0px, -0.375rem)",
    backgroundColor: 'transparent',
    color: "black",
    fontSize: "0.875rem",
    fontWeight: "600",
    opacity: "0.8",
    "&.Mui-focused": {
      color: "black", // Set label color when focused
    },
  },
  inputUnderline: {
    "&:before": {
      borderBottom: "none", // Remove default underline
    },
    "&:after": {
      borderBottom: "none", // Remove underline after focus
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none", // Remove underline on hover
    },
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const DateFilter = ({ filterByDate, setfilterByDate }) => {
  const classes = useStyles();
  const selectOptions = [
    { id: "year", label: "This year" },
    { id: "month", label: "This month" },
    { id: "week", label: "This week" },
  ];

  const handleChange = (event) => {
    setfilterByDate(event.target.value);
  };
  return (
    <div className={styles.dateFilterWrapper}>
      <InputLabel
          style={{color: '#FFF'}}
        classes={{ root: classes.label }} // Apply the custom label styles
        id="selectboxInputLabel">
         Filter by date
      </InputLabel>
      <FormControl>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          classes={{
            select: classes.select,
            icon: classes.icon,
            underline: classes.inputUnderline,
          }}
          style={{color: '#FFF', borderColor: "#FFFFFF"}}
          value={filterByDate}
          onChange={handleChange}>
          {selectOptions?.map((option) => (
            <MenuItem value={option?.id}>{option?.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const CreatedNfts = ({ id, createdMenuIndex, page, setPage }) => {
  const [filterByDate, setfilterByDate] = useState("year");
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { search } = useLocation();
  const [screenWidth] = useWindowSize();
  const [auctionParams, setAuctionParams] = useState({
    limit: 10,
    page: 1,
  });
  const euroValue = useSelector((state) => state.counter.euroValue);

  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };

  useEffect(() => {
    const urlParam = queryString.parse(search);
    if (urlParam?.pn) {
      //will set page as 1 which is the tab value
      setPage(urlParam?.pn);
    }
  }, []);

  const getNFTData = async (createdId, filterByDate) => {
    let parameters = auctionParams;
    switch (createdId) {
      case 0:
        parameters = {
          ...auctionParams,
          filterBy: filterByDate,
        };
        break;
      case 1:
        parameters = { ...auctionParams, filterBy: filterByDate };
        break;
      default:
        break;
    }
    config.params = parameters;
    try {
      if (createdId == 0) {
        let activeNft = await axiosInstance.get(
          "/user/creator/myAssets/active",
          config
        );
        // console.log("aassss", activeNft);
        setData(activeNft?.data?.activeAssets);
        setCount(activeNft?.data?.metadata?.totalPages);
        setIsLoading(false)
      } else {
        let soldNft = await axiosInstance.get(
          "/user/creator/myAssets/sold",
          config
        );
        console.log("aassss", soldNft);
        setData(soldNft?.data?.soldAssets);
        setCount(soldNft?.data?.metadata?.totalPages);
        setIsLoading(false)
      }
    } catch (error) {
      setData([]);
    }

    //   setCount(userData?.data?.result?.totalPages);
  };
  useEffect(() => {
    getNFTData(createdMenuIndex, filterByDate);
  }, [page, filterByDate]);

  useEffect(() => {
    const urlParam = queryString.parse(search);
    getNFTData(createdMenuIndex);
    if (!urlParam?.pn || urlParam?.pn == 1) {
      setPage(1);
    }
  }, [createdMenuIndex]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setPage(selectedPage + 1);
    setAuctionParams({ ...auctionParams, page: selectedPage + 1 });
    history.push(
      `/dashboard?tab=${id}&ctab=${createdMenuIndex}&pn=${selectedPage + 1}`
    );
  };
  return (
    <>
      <div className={styles.filterContainer}>
        <DateFilter
          filterByDate={filterByDate}
          setfilterByDate={setfilterByDate}
        />
      </div>
      {isLoading && (
              <div className={styles.loaderContent}>
                <CircularProgress style={{color: "#FFF"}} />
              </div>
      )}
      <div className={styles.viewContainer}>
        {data?.length > 0 ? (
          data?.map((x, index) => (
            <Card
              createdId={createdMenuIndex}
              className={styles.card}
              item={x}
              key={index}
              price={euroValue}
              type={"view"}
              hidePurchaseToolBar={true}
              details={x}
              marketplace={true}
              sourcePage={"createdNFTsProfile"}
            />
          ))
        ) : (
          !isLoading && <div className={`noRecordsFound ${styles.noRecordsFound}`}>
            <span>No records found.</span>
          </div>
        )}

        {data?.length > 0 && (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={count}
            onPageChange={handlePageClick}
            forcePage={page - 1}
            containerClassName={styles.pagination}
            previousLinkClassName={cn(styles.pagination__link, "page_switch")}
            // previousClassName={cn(styles.pagination__button, "page_switch")}
            nextLinkClassName={cn(styles.pagination__link, "page_switch")}
            pageClassName={"page_no"}
            disabledClassName={styles.pagination__link__disabled}
            activeClassName={styles.pagination__link__active}
            pageRangeDisplayed={screenWidth < 640 ? 1 : 3}
            marginPagesDisplayed={screenWidth < 640 ? 1 : 4}
          />
        )}
      </div>
    </>
  );
};

export default CreatedNfts;
