import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { axiosInstance } from "../../../utils/API";
import { PopUpAlert, current_datetime } from "../../../controller/utils";
import { Divider } from "@material-ui/core";
import moment from "moment";
import useWindowSize from "../../../common/hooks/useWindowSize";

const useStyles = makeStyles({
  table: {
    minWidth: 900,
    backgroundColor: 'transparent',
    border: '0px',
    marginBottom: '2rem'
  },

  cellRoot: {
    color: '#8B9CA9',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    border: '0px',
    textAlign: 'center'
  },

  bodyCellRoot: {
    fontFamily: "'Poppins'",
    backgroundColor: '#3E2F5F',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "1.688rem",
    color: "#FFF",
    textAlign: "center",
    textTransform: "capitalize",
    border: '0px',
    padding: '1rem',
  },

  transactionContainer: {
    borderRadius: "0.625rem",
    backgroundColor: "#F8F8F8",
    padding: "0.625rem 1.25rem !important",
    margin: "1.125rem 0px",
  },
  firstSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.625rem 0",
    "& span": {
      fontWeight: "500",
      fontSize: "1.125rem",
    },
    "& b": {
      fontSize: "0.938rem",
    },
  },
  secondSection: {
    padding: "0.625rem 0",
    "& p": {
      color: "rgba(75, 75, 75, 0.79)",
    },
    "& b": {
      fontSize: "0.938rem",
    },
  },
  deposit: {
    color: "#24B200",
  },
  withdraw: {
    color: "#ED2100",
  },
});

export default function TransactionHistoryTable({ refreshPage, screenwidth }) {
  const classes = useStyles();
  const [transactionData, setTransactionData] = useState([]);

  const [screenWidth] = useWindowSize();

  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    const callAPI = async () => {
      // await getTransactionHistory();
      await getAccountHistory()
    };
    if (token) {
      callAPI();
    }
  }, [refreshPage]);

  // Fetch Transaction history
  const getTransactionHistory = async () => {
    const endpoint = `/user/transactions?page=1`;
    await axiosInstance
      .get(endpoint, config)
      .then((res) => {
        if (res) setTransactionData(res.data?.result?.transactions?.rows);
        else setTransactionData([]);
      })
      .catch((err) => {
        PopUpAlert("Alert", err.response.data.message, "warning");
      });
  };

  const getAccountHistory = async () => {
    const endpoint = `/topuphistory?page=1&&limit=100`;
    await axiosInstance
      .get(endpoint, config)
      .then((res) => {
        if (res) setTransactionData(res.data?.result?.topupHistory?.rows);
        else setTransactionData([]);
      })
      .catch((err) => {
        PopUpAlert("Alert", err.response.data.message, "warning");
      });
  };

  const getRemark = (remark) => {
    if (remark != null) {
      let lastWord = remark.split(" ").pop();
      if (lastWord.includes(".")) {
        return remark.slice(0, -1);
      } else return remark;
    } else return "-";
  };

  // Used material UI Table.
  return (
    <>
      {screenwidth > 766 || true ? (
        <TableContainer component={Paper} style={{
          backgroundColor: 'transparent', border: '0px'
        }}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root: classes.cellRoot,
                  }}
                  style={{ width: "18%" }}
                >
                  Date and time
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.cellRoot,
                  }}
                  style={{ width: "18%" }}
                >
                  Transaction type
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.cellRoot,
                  }}
                  style={{ width: "12%" }}
                >
                  Status
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.cellRoot,
                  }}
                  style={{ width: "42%" }}
                >
                  Txn Hash
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.cellRoot,
                  }}
                  style={{ width: "10%" }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionData.map((row) => (
                <TableRow key={row?.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    classes={{
                      root: classes.bodyCellRoot,
                    }}
                  >
                    {moment
                      .utc(row.createdAt)
                      .local()
                      .format("DD MMM YYYY h:mm:ss A")}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.bodyCellRoot,
                    }}
                  >
                    {row?.transactionType ? row?.transactionType : "-"}{" "}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.bodyCellRoot,
                    }}
                  >
                    {row?.status ? row?.status : "-"}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.bodyCellRoot,
                    }}
                    style={{ textTransform: "none" }}
                  >
                    {row?.transactionHash ? row?.transactionHash : "-"}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.bodyCellRoot,
                    }}
                  >
                    {/* {row?.amount && Number(row?.amount).toFixed(2)} */}
                    {row?.baseAmount
                    ? Number(row.baseAmount).toFixed(2) + " " + (row.base ? row.base : row.quote)
                    : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {transactionData.map((row) => (
            <div className={classes.transactionContainer}>
              <div className={classes.firstSection}>
                <b>
                  {row?.actionType
                    ? row?.actionType.charAt(0).toUpperCase() +
                      row?.actionType.slice(1)
                    : "-"}
                </b>
                <span
                  className={
                    row?.actionType?.includes("deposit")
                      ? classes.deposit
                      : classes.withdraw
                  }
                >
                  $ {row?.amount} {row.base}
                </span>
              </div>
              <Divider />
              <div className={classes.secondSection}>
                <b>{row?.remark ? row?.remark : "-"}</b>
                <p>
                  {moment
                    .utc(row.createdAt)
                    .local()
                    .format("DD/MM/YYYY h:mm:ss A")}
                </p>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
