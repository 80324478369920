import {Col, Container, Row} from "react-bootstrap";

import './style.scss'
import { useHistory } from "react-router";

function Highlights() {

    const history = useHistory();

    return(
        <Container className={'lii-section mt-5 mt-md-0'}>
            <Row>
                <Col md={5} className={'highlightLeft d-flex flex-column pe-md-2'}>
                    <h3>Discover Our
                        Marketplace: Key
                        Features and Benefits
                        Unveiled</h3>
                    <p>Explore diverse features and
                        benefits for an extraordinary
                        experience.</p>
                    <button style={{width: '200px'}} className={'d-md-block d-none lii-view-more d-flex align-items-center my-2'} onClick={() => {
                        history.push('/explore/')
                    }}>View more <img src={'/Icons/two-dots.svg'} /></button>
                </Col>
                <Col md={7} className={'highlightRight'}>
                    <Row className={''}>
                        <Col xs={4} style={{
                            backgroundImage: `url(/images/highlights/1.png)`,
                            backgroundSize: 'cover'
                        }}>
                            <div className={'number'}>1</div>
                            <div className={'title'}>Authenticity assured</div>
                            <div className={'desc'}>Immerse in a world of
                                trusted digital assets.</div>
                        </Col>
                        <Col xs={4} style={{
                            backgroundImage: `url(/images/highlights/2.png)`,
                            backgroundSize: 'cover'
                        }}>
                            <div className={'number'}>2</div>
                            <div className={'title'}>Trade, buy and sell</div>
                            <div className={'desc'}>unleash
                                possibilities in our
                                dynamic secondary
                                market.</div>
                        </Col>
                        <Col xs={4} style={{
                            backgroundImage: `url(/images/highlights/3.png)`,
                            backgroundSize: 'cover'
                        }}>
                            <div className={'number'}>3</div>
                            <div className={'title'}>Hybrid Accessibility</div>
                            <div className={'desc'}>for
                                Web2 and Web3 Users.</div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}><button onClick={() => history.push('/explore/')} className={'m-auto d-md-none d-block lii-view-more d-flex align-items-center my-2'}>View more <img src={'/Icons/two-dots.svg'} /></button></Col>
            </Row>
        </Container>
    )

}

export  default Highlights;
