import React from "react";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import './style.scss'

const Faq = () => {

    const faqData = [

        {
            question: "Where can I locate my wallet address?",
            answer:
                `<p>
<b>Finding your wallet address is a straightforward process. Follow these steps:</b>
<ol style="list-style: decimal;">
<li>From the Home Page: Go to the home page of our platform.
<li>Access Your Profile: Locate the avatar photo on the top right of the screen and Click on "Profile" from the dropdown menu.
<li>Navigate to "Wallet": Once on your profile page, look for the "Manage Wallet" section. it is positioned on the top right of the screen.
<li>Locate Your Wallet Address: In the "Manage Wallet" section, you will find your lii wallet balance.
Your wallet address usually starts with "0x…"; this is your unique wallet address.
By following these steps, you can easily find and identify your wallet address. If you encounter any issues or have further questions, please don't hesitate to contact our support team for assistance at support@lii.market.
</li>
</ol>
</p>`,
        },
        {
            question: "Who is the custodian of my lii wallet?",
            answer:
                `<b>You are the sole custodian of your lii wallet. Here's what you need to know:</b><br/>
<b>Connected to your Email</b>: <br/>Your wallet is associated with your email address.<br/>Sign in to your wallet using your email credentials.<br/>
<b>Consistency is Key</b>: <br/>Always sign in with the same email address to maintain continuity. <br/>Different email addresses will result in different wallets.<br/>
<b>Security Measures</b>: <br/>Be aware that if your email is compromised, it could jeopardize your lii wallet. <br/>Utilize multi-factor authentication for enhanced security.<br/>
<b>Private Key Access</b>: <br/>You have the ability to reveal the private keys of your wallet.
<br/>Visit your profile page, go to the Wallet section, and click on "Reveal Private Keys."
<br/>Follow the steps presented on the screen.
<br/>It's crucial to note that you are the sole person capable of viewing the private keys of your wallet.
<br/>Ensuring the security of your lii wallet is of utmost importance. If you have additional questions or require assistance, please reach out to our support team at support@lii.market`,
        },
        {
            question: "How can I resell my purchased items on the secondary market?",
            answer: `<b>Reselling items on the secondary market is a straightforward process. Follow these steps:</b>
<ol style="list-style: decimal;">
<li>Navigate to Your Profile: Go to your profile on the platform.</li>
<li>Access Wallet: Click on "Wallet".</li>
<li>Manage Balance: Choose "Manage Balance."</li>
<li>Top up: Click on the "Top Up" tab.</li>
<li>Select Currency and Proceed: Choose the currency you want to top up (e.g., Matic) from the drop-down menu. Proceed to buy Matic using your credit or debit card.</li>
<li>Complete Transaction: After the transaction is complete, return to the resale of the digital asset. Feel free to contact our support team at support@lii.market ,if you encounter any issues or have additional questions.</li>
</ol>
`,
        },
        {
            question: "How to Top Up Your Wallet?",
            answer: `
<ol style="list-style: decimal;">
<li>Navigate to Your Profile: Go to your profile on the platform.</li>
<li>Access Purchased Items: Click on "Purchases".</li>
<li>Select the Item to Sell: Choose the specific item you wish to sell, then click on it.</li>
<li>Initiate Sale: Click on "Sell on Marketplace".</li>
<li>Set Price and Publish: Set your desired price for the digital card. Click on "Publish" to make your listing available on the marketplace.
<br/><b>Note:</b><br/> The blockchain will charge a transaction fee for listing your digital card for sale. Ensure your Matic wallet has a sufficient balance for the transaction. If your wallet lacks Matic, you'll be prompted to top it up.
</li>
</ol>     
            `,
        },
        {
            question: "How does the auction process work?",
            answer: `<p>
<b>An auction is a process where digital assets are sold to the highest bidder. 
Here's a step-by-step breakdown:</b>

<ol style="list-style: decimal;">
<li>Start and End Dates:
Each auction has a specified start and end date.
Users can participate in the auction during this time frame.</li>
<li>
Placing Bids:
Users can place their bids on the digital asset they're interested in.
It's important to note that users can bid again only if they have been outbid by another participant.
</li>
<li>
Outbidding:
If a user is outbid, they have the option to place a higher bid before the auction's end time.
</li>
<li>
Auction Conclusion:
Once the auction time expires, the digital asset is transferred to the highest bidder.
The winning bidder is the one who has placed the highest bid when the auction concludes.
</li>
</ol>
</p>`,
        },
        {
            question: "What are Claimed Assets?",
            answer: `<p>
<b>Claimed assets are digital collectibles available for free upon user request. Here's how the process works:</b><br/>
<Br/>
<b>Claiming a Digital Collectible:</b><br/>
Users can claim a digital collectible for free within the specified request time frame.<br/>
<b>Review by the Creator:</b><br/>
The creator of the digital asset will review the request.<br/>
<b>Creator's Decision:</b><br/>
The creator has the sole discretion to either accept or reject the user's request.<br/>
<b>Upon Acceptance:</b><br/>
If the request is accepted, the requested digital card will be visible in the user's purchases.<br/>
This process allows users to express interest in specific digital collectibles without any cost. However, the acceptance of requests is at the discretion of the digital asset creator. If you have additional questions or encounter any issues, feel free to reach out to our support team for assistance at support@lii.market
</p>`,
        },
        {
            question: "Why is there no password required for login?",
            answer: `<p>
<b>In lii.market, login operates differently from traditional web2 platforms. Here's why:</b> <br/>
<b>Web3 Marketplace:</b><br/>
lii.market operates as a web3 marketplace, utilizing web3 technology.<br/>
<b>Web3 Wallet Instead of Password</b>:<br/>
Unlike web2 platforms that use usernames and passwords, lii.market employs a web3 wallet for login.<br/>
<b>Magic Login:</b><br/>
The login process involves Magic Login.<br/>
Upon entering your email address, a login code is sent to your email.<br/>
<b>Email Authentication:</b><br/>
The login code serves as authentication and grants access to the platform.<br/>
With each login, the user enters the email and the corresponding login code.<br/>
<b>Web3 Wallet Creation:</b><br/>
When a user logs in with their email through Magic Login, a web3 wallet is created.<br/>
This wallet is associated with the email used for login.<br/>
<b>Consistent Login Method:</b><br/>
To maintain access, users need to log in with the same email and enter the login code sent to that email.<br/>
If you have any questions or require further assistance, feel free to contact our support team at support@lii.market.

</p>`,
        },
        {
            question: "What wallets are supported for login to lii.market?",
            answer: `<p>
<b>lii.market currently supports the following web3 wallets for user logins to the marketplace:</b><br/>
<b>Metamask</b>:<br/>
Users can connect their Metamask wallet to lii.market for a seamless web3 login experience.<br/>
<b>Trust Wallet</b>:<br/>
Trust Wallet is also supported, allowing users to log in to lii.market using their Trust Wallet.<br/>
These wallets provide users with secure and convenient access to the marketplace. If you have any questions about wallet compatibility or encounter issues, please reach out to our support team for assistance at support@lii.market 

</p>`,
        },
        {
            question: "What does the Earnings tab display?",
            answer: `<p>
<b>The Earnings tab on lii.market provides a comprehensive record of your sales. Here's what you can expect to see:</b>
<br/><br/>
<b>Sales Register:</b><br/>
The Earnings tab serves as a register showcasing all the sales you have made on the platform.<br/>
<b>Information Displayed:</b><br/>
For each sale, you can view the buyer's name, the sale amount, and the date of the transaction.<br/>
<b>Transaction Visibility:</b><br/>
Access additional details by clicking on a the “View” tab to view the transaction on Polygon Scan for a more in-depth look.<br/>
This feature offers a transparent overview of your sales activities, enabling you to track and manage your earnings effectively. If you have further questions or need assistance, feel free to contact our support team at support@lii.market 

</p>`,
        },
        {
            question: "What is the purpose of the Wishlist tab?",
            answer: `<p>
<b>The Wishlist tab on lii.market serves as a personalized collection of digital cards that users have expressed interest in. Here's how it works:</b><br/>
<b>Exploring the Marketplace:</b>
<br/><br/>
As users browse the marketplace, they can find digital cards of interest.<br/>
<b>Adding to Wishlist:</b><br/>
Users can press the heart icon located at the bottom of each digital card to save it to their Wishlist.<br/>
The Wishlist tab essentially acts as a curated collection of desired digital cards, allowing users to easily keep track of items they may be interested in acquiring in the future.

</p>`,
        },
        {
            question: "What are the steps to purchase a digital card on lii.market?",
            answer: `<p>
<b>Buying a digital card on lii.market is a straightforward process. Follow these steps:</b><br/>
<b>Explore the Marketplace:</b><br/>
Browse the marketplace to find the digital card of your liking.<br/>
<b>Select the Card:</b><br/>
Click on the digital card you're interested in to navigate to its main page.<br/>
<b>Review Card Information:</b><br/>
On the card's main page, find the "Buy" tab.<br/>
Read details about the digital card, including information about the creator.<br/>
<b>Read and Agree to Terms:</b><br/>
Before proceeding, read and agree to the terms and conditions of the marketplace.<br/>
<b>Checkout Process:</b><br/>
Click on the "Buy" tab to initiate the purchase.<br/>
<b>Payment Options:</b><br/>
In the checkout tab, you can choose to pay with a debit/credit card or through cryptocurrency.<br/>
<br/>
<b>Debit/Credit Card Payment:</b><br/>

If selecting debit/credit card, you'll be directed to our third-party service provider, WERT.<br/>
Provide the necessary information for a one-time setup.<br/>
Once completed, your payment will be processed, and the digital card will be transferred to your wallet.<br/>
<b>View Purchased Digital Cards:</b><br/>
Check your profile section under the "Purchased Items" tab to view the digital cards you've bought.
This process ensures a secure and seamless experience for users purchasing digital cards on lii.market. If you have any questions or encounter issues, please reach out to our support team for assistance at support@lii.market 
</p>`,
        },
        {
            question: "What does Complete KYC mean on lii.market?",
            answer: `<p>
<b>KYC stands for Know Your Client, and it is an integral part of our Anti-Money Laundering (AML) policy. When a user reaches a purchase threshold of $1,000, either in total or in a single purchase, lii.market will prompt users to complete the KYC process. Here's how you can do it:</b><br/>
<b>Access from Profile Page:</b><br/>
Navigate to your profile page.<br/>
<b>Edit Profile Section:</b><br/>
Find the "Edit Profile" section.<br/>
<b>Complete KYC:</b><br/>
Click on "Complete KYC."<br/>
<b>Follow the Required Steps:</b><br/>
Go through the necessary steps to complete the KYC process.<br/>
<b>Continue Shopping:</b><br/>
Once KYC is successfully completed, you can continue shopping on the platform.<br/>
This KYC process ensures compliance with regulatory standards and enhances the security of transactions on lii.market. If you have any questions or need assistance during the KYC process, please feel free to contact our support team at support@lii.market 
</p>`,
        },
        {
            question: "What does the Reveal Private Keys tab allow me to do?",
            answer: `<p>
<b>The Reveal Private Keys tab on lii.market provides users with the ability to unveil the private keys associated with their wallet. Here's how it works:</b><br/>
<b>Private Keys Importance:</b><br/>
Private keys serve as the essential access keys to your wallet.<br/>
It's crucial not to share these keys with anyone and to store them securely.<br/>
<b>Revealing Private Keys:</b><br/>
Users have the option to reveal the private keys of their wallet on lii.market.<br/>
<b>Access from Profile Page:</b><br/>
Visit your profile page on the platform.<br/>
<b>Navigate to Wallet Section:</b><br/>
Go to the Wallet section of your profile.<br/>
<b>Click on "Reveal Private Keys":</b><br/>
Locate and click on the "Reveal Private Keys" tab.<br/>
<b>Follow on-screen Steps:</b><br/>
Follow the steps presented on the screen to complete the process.<br/>
<b>Sole Person Viewing:</b><br/>
It's essential to note that you, as the user, are the sole person capable of viewing the private keys of your wallet.<br/>
Security is a top priority, so ensuring the confidentiality of your private keys is crucial. If you have additional questions or need assistance, please feel free to contact our support team at <a href="mailto:support@lii.market">support@lii.market</a>.
</p>`,
        },
        {
            question: "What is the Enable MFA Tab?",
            answer: `<p>The "Enable MFA" tab on lii.market serves as the gateway to activating Multi-Factor Authentication (MFA) for a more secure login process. By choosing to enable MFA, users significantly enhance the protection of their accounts, thwarting unauthorized access attempts. This tab is conveniently located on the profile page under the wallet section. Once users activate MFA, they will be prompted to download Google Authenticator. With MFA activated, each login requires the user to enter both the login code sent to their email and the code generated by Google Authenticator, adding an extra layer of verification to the authentication process. This robust security measure ensures that only authorized users gain access to their accounts on lii.market, providing peace of mind and safeguarding against potential security threats. If users have any questions or need assistance with the MFA setup, our support team is available to assist at support.lii.market </p>`,
        },
        {
            question: "What is the Manage Balance Tab?",
            answer: `<p>The Manage Balance tab within the wallet section of the user's profile provides a platform for users to oversee and regulate their wallet balance effectively. Users are presented with two key options: the first enables the withdrawal of USDC from the wallet, allowing users to transfer funds to any crypto exchange or alternative web3 wallet. The second option facilitates the topping up of the wallet, allowing users to deposit cryptocurrency, either in USDC or Matic. Matic tokens play a crucial role in covering the gas fees associated with transactions on the Polygon blockchain. It's important to note that while all items on the marketplace are priced in USDC (equivalent to 1 US dollar), any sales made on this platform will be credited in USDC to the user's account. If users have any inquiries or require assistance in managing their balance, our support team is available to help at <a href='mailto:support@lii.market'>support@lii.market</a></p>`,
        },
        {
            question: "What is USDC?",
            answer: `<p>USDC, or USD Coin, is a type of stablecoin in the cryptocurrency space. It is a digital asset pegged to the value of the United States Dollar (USD) on a one-to-one basis. This means that one USDC is intended to have a value equivalent to one US dollar. The purpose of stablecoins like USDC is to provide the benefits of cryptocurrencies, such as fast and secure transactions on blockchain networks, while minimizing the price volatility often associated with other cryptocurrencies like Bitcoin or Ethereum. USDC is commonly used for various transactions and trading activities within the crypto ecosystem and is considered a reliable representation of the US dollar in digital form. On platforms like lii.market, USDC is frequently utilized as the standard currency for pricing items and conducting transactions.</p>`,
        },
        {
            question: "What is the Matic Token?",
            answer: `<p>The Matic token, now known as MATIC, is the native cryptocurrency of the Polygon network. Polygon is a Layer 2 scaling solution for the Ethereum blockchain, designed to improve scalability and reduce transaction costs. MATIC serves several essential functions within the Polygon ecosystem:
Gas Fees: MATIC tokens are used to pay for transaction fees, known as gas fees, on the Polygon network. Users need MATIC tokens to process and validate transactions, execute smart contracts, and interact with decentralized applications (DApps) on Polygon.
</p>`,
        },
        {
            question: "What is the purpose of topping up my wallet?",
            answer: `<p>Topping up your wallet refers to the procedure of adding funds to your lii wallet using a credit/debit card. Typically, users are prompted to top up their wallet when their Matic balance is insufficient to cover the associated blockchain transaction fees.</p>`,
        },
        {
            question: "How do I list my NFT for sale on the secondary market? ",
            answer: `<p>When signed in to your account on lii</p><p>Click on my lii</p><p>Go to dashboard.</p><p>Select the NFT you wish to sell.</p><p>Click “list on marketplace” 
        </p><p>Choose the asking price</p><p>Click “done”</p>`,
        },
        {
            question: "What is the minimum transaction amount for debit/credit card payments?",
            answer: `<p>The minimum transaction amount for credit card payments is $5. Transactions below $5 can be settled using crypto payments. </p>`,
        },
        {
            question: "How can I get in touch with customer support?",
            answer: `<p>You can reach out to our support team by sending an email to <a href='mailto:support@lii.market'>"support@lii.market</a>.</p>`,
        },
        {
            question: "Why is my transaction taking time to be processed?",
            answer: `<p>Transactions may take a few minutes to process due to blockchain congestion on the Polygon network. The maximum expected time for completion of a transaction is 20 minutes.</p>`,
        },
        {
            question: "Why was my transaction rejected?",
            answer: `<p>Transactions can be rejected for various reasons, including payment errors or delays in blockchain transactions. For more information about a specific transaction, please contact our support team via email at <a href='mailto:support@lii.market'>"support@lii.market</a>.</p>`,
        },
        {
            question: "How can I get a refund?",
            answer: `<p>Transactions on the blockchain cannot be reversed, and so lii does not provide refunds for purchases made on the platform.</p>`,
        }
    ];

    return (
        <Container>
            <Row>
                <Col>
                    <div id={'aboutHeader'} className={'mb-5'}>
                        <h2>Frequently <p>Asked Questions</p></h2>
                    </div>
                </Col>
            </Row>
            <Row id={'accordionFAQ'}>
                <Col>
                    <Accordion defaultActiveKey="0">
                        {faqData.map((item, i) => {
                            return <Accordion.Item eventKey={i}>
                                <Accordion.Header>{item.question}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{__html: item.answer}}></Accordion.Body>
                            </Accordion.Item>
                        })}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default Faq;
