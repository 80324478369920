import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

const About = () => {

    const features = [
        {
            title: 'Verified Digital Assets',
            desc: 'Immerse in a world of authenticity with verified digital assets, enhancing your unique online experience.',
            icon: '/Icons/digital_assets.svg'
        },
        {
            title: 'Secondary Market Dynamics',
            desc: 'Unlock endless possibilities—trade, buy, sell in our dynamic secondary market for a thriving digital ecosystem.',
            icon: '/Icons/secondary_market.svg'
        },
        {
            title: 'Hybrid Accessibility',
            desc: 'Best of both worlds—seamless integration for a universal experience connecting Web2 and Web3 users effortlessly.',
            icon: '/Icons/hybrid_accessibility.svg'
        },
        {
            title: 'User-Friendly Interface',
            desc: 'Simplify your journey—explore, bid, and engage effortlessly with our intuitive and user-friendly market design.',
            icon: '/Icons/user_friendly.svg'
        }

    ]

    return <Container>
            <Row >
                <Col md={{span: 7, order: 0}} xs={{order: 1}} className={'d-flex align-items-center'}>
                    <div id={'aboutHeader'} className={'my-md-5 mt-5 text-center ps-md-5'}>
                        <h2>Step into the future of <p>entertainment</p>  </h2>
                    </div>
                </Col>
                <Col md={{span: 4, order: 1, offset: 0}} xs={{order: 0, span: 6, offset: 3}} className={'text-center mt-5'}>
                    <img src={'/images/aboutus.png'} className={'img-fluid'}/>
                </Col>
            </Row>
            <Row className={'px-5 aboutFeatures my-5'}>
                <Col xs={12}>
                    <div className={'aboutFeature my-5 flex-column'}>
                        <h1>Marketplace Features</h1>
                        <p>Explore Lii's key features shaping your digital journey ahead.</p>
                    </div>
                </Col>
                {features.map((f) => {
                    return <Col md={3} className={'my-4 my-md-0'}>
                        <div className={'gridSection'}>
                            <img src={f.icon} className={'img-fluid'}/>
                            <h2 className={'my-2'}>{f.title}</h2>
                            <p>{f.desc}</p>
                        </div>
                    </Col>
                })}
            </Row>
            <Row className={'aboutFeature my-5'}>
                <Col md={7}>
                    <h1 className={'my-2 text-md-start'}>
                        <img src={'/images/rocket.png'} width={50} className={'d-inline-block d-md-none'}/>
                        Mission
                    </h1>
                    <p className={'text-justify text-md-start'}>At lii, we embark on a mission to revolutionize the
                        entertainment industry
                        through the transformative power of Web3 technology. Dedicated to reshaping music, ticketing,
                        gaming, sports, collectibles, and more. Understanding that true transformation requires
                        accessibility and simplicity, lii is committed to breaking down barriers. lii.market provides a
                        simplified Web2-style user experience, ensuring everyone can easily embrace Web3's power without
                        its
                        complexities.</p>
                </Col>
                <Col md={5} className={'d-none d-md-block'}>
                    <img src={'/images/rocket.png'} className={'img-fluid'}/>
                </Col>
            </Row>
            <Row className={'aboutFeature my-5'}>
                <Col md={5} className={'d-none d-md-block'}>
                    <img src={'/images/telescope.png'} className={'img-fluid'}/>
                </Col>
                <Col md={7}>
                    <h1 className={'my-2 text-md-start'}>
                        <img src={'/images/telescope.png'} width={100} className={'d-inline-block d-md-none'}/>
                        Vision
                    </h1>
                    <p className={'text-justify text-md-start'}>Step into the future of entertainment with Lii, where
                        our vision is to be
                        the catalyst for a paradigm shift in the industry. We aim to set new standards in music,
                        ticketing,
                        collectibles, gaming, sports, and more by seamlessly integrating Web3 technology into every
                        aspect
                        of your experience. Our commitment to accessibility envisions a future where individuals
                        effortlessly engage with the benefits of Web3 technology. Lii is not just a platform; it's a
                        pathway
                        to a more enriched, user-friendly entertainment landscape. Join us as we redefine possibilities
                        and
                        enhance the entertainment experience for all.
                    </p>
                </Col>
            </Row>
            <Row className={'aboutFeature'}>
                <Col className={'d-block d-md-none'}>
                    <img src={'/images/empowerment.png'} className={'img-fluid'}/>
                </Col>
                <Col md={7}>
                    <h1 className={'my-2 text-start'}>Empowerment And Inclusion</h1>
                    <p className={'text-start'}>At lii, empowerment and inclusion are at the heart of our mission. We
                        focus
                        on bringing positive change to the MENA region by empowering celebrities, brands and users
                        through
                        innovative solutions
                        <ul className={'p-5 p-lg-2'}>
                            <li style={{
                                listStyleImage: 'url(/images/check_icon.png)'
                            }}> We revolutionize how celebrities and brands engage with their audience, offering unique
                                opportunities for them to monetize through Web3, unlocking new revenue streams.
                            </li>
                            <li style={{
                                listStyleImage: 'url(/images/check_icon.png)'
                            }}>Our platform integrates a sophisticated royalty system, safeguarding the intellectual
                                property of celebrities and brands, ensuring fair compensation for their work and
                                creativity.
                            </li>
                            <li style={{
                                listStyleImage: 'url(/images/check_icon.png)'
                            }}>lii introduces a dynamic secondary market for buying and selling collectibles, enhancing
                                the
                                value of digital assets, and providing new avenues for celebrities and brands to connect
                                with
                                their fans.
                            </li>
                            <li style={{
                                listStyleImage: 'url(/images/check_icon.png)'
                            }}>Through lii, fans gain unprecedented access to digital collectibles and services,
                                democratizing content and allowing fans to directly support their favorite celebrities
                                and
                                brands with exclusive offerings.
                            </li>
                        </ul>
                    </p>
                </Col>
                <Col className={'d-none d-md-block'}>
                    <img src={'/images/empowerment.png'} className={'img-fluid'}/>
                </Col>
            </Row>
        </Container>
};

export default About;
