import useWindowSize from "../../../../common/hooks/useWindowSize";

function DescriptionTab({details}) {

    const [screenWidth] = useWindowSize();
    const infoTabStyle = {
        color: '#F2F6F9',
        fontFamily: 'Poppins',
        fontSize: screenWidth<768?'15px':'20px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal'
    }

    return(
        <div style={infoTabStyle} dangerouslySetInnerHTML={{__html: details?.description}}></div>
    )
}

export default DescriptionTab;
