import {Col, Container, Row} from "react-bootstrap";
import Carousel from 'react-spring-3d-carousel';
import {config as springConfig} from "react-spring"
import {useEffect, useState} from "react";
import './style.scss'
import API from "../../../utils/API";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

function ThreedSlider() {

    const history = useHistory();
    const walletStatus = useSelector((state) => state?.counter?.value);

    const [slides, setSlides] = useState([])
    const [curAsset, setCurAsset] = useState(null)
    const [slideNb, goToSlide] = useState(0)

    const [config, setConfig] = useState({
        offsetRadius: 1,
        showNavigation: false,
        enableSwipe: true,
        config: springConfig.gentle,
    })

    const getTouches = (evt) => {
        return (
            evt.touches || evt.originalEvent.touches // browser API
        );
    };

    const handleTouchStart = (evt) => {
        if (!config.enableSwipe) {
            return;
        }

        const firstTouch = getTouches(evt)[0];
        config.xDown = firstTouch.clientX
        config.yDown = firstTouch.clientY
        setConfig(config)
    };

    const handleTouchMove = (evt) => {


        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = config.xDown - xUp;
        let yDiff = config.yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                goToSlide(slideNb == slides.length - 1 ? 0 : slideNb + 1)
                config.xDown = null
                config.yDown = null
                setConfig(config)

            } else {
                goToSlide(slideNb == 0 ? slides.length - 1 : slideNb - 1)
                config.xDown = null
                config.yDown = null
                setConfig(config)
            }
        }
    };

    useEffect(() => {
        const elm = document.getElementById("threedSlider");
        if(elm)
            elm.style.height = window.innerHeight / 2 + 'px';
    }, [slides])

    useEffect(() => {
        if(slides && slides.length > 0){
            setCurAsset(slides[slideNb].asset)
        }

    }, [slides, slideNb])

    useEffect(() => {
        let config = {
            headers: {
                Authorization: localStorage.getItem("userToken"),
            },
        };
        API.get("/asset/most-viewed", walletStatus ? config : "").then((res) => {
            const tmpSlides = res.data?.result?.map((item) => ({
                key: item.id,
                content: <img src={item.mediaPreviewUrl} alt={item.name}/>,
                asset: item
            })).map((slide, index) => {
                return {
                    ...slide, onClick: () => {
                        goToSlide(index);
                    }
                };
            });
            setSlides(tmpSlides);
        });
    }, [])

    return (
        <Container className={'lii-section'}>
            {slides.length > 0 &&
                <Container>
                    <Row className={'mt-auto'}>
                        <Col md={12}>
                            <h1 className={'sectionTitle m-0 p-0'}>Trending Now</h1>
                            <p className={'sectionDesc m-0 p-3'}>Explore the latest and
                                trendiest items.</p>
                        </Col>
                    </Row>
                    <Row id={'threedSlider'} className={'text-center mb-auto'}>
                        <Col md={{span: 10, offset: 1}}
                             onTouchStart={handleTouchStart}
                             onTouchMove={handleTouchMove}>

                            <Carousel slides={slides}
                                      goToSlide={slideNb}
                                      offsetRadius={config.offsetRadius}
                                      showNavigation={config.showNavigation}
                                      animationConfig={config.animationConfig}/>
                        </Col>
                    </Row>
                    <Row onClick={() => history.push(`/item/${curAsset?.slug}`)}>
                        <Col md={{span: 4, offset: 4}} className={'text-center text-white nftDetails mt-3'}>
                            {curAsset?.purchasedBy
                                ? `${curAsset?.name} #${curAsset?.currentEdition}/${curAsset?.totalEdition}`
                                : curAsset?.name}
                        </Col>
                    </Row>
                    {curAsset &&
                        <Row >
                            <Col xs={{span: 2}} md={{span: 1, offset: 3}} className={'slideControl'}><img
                                src={'/images/trends/left.svg'} className={'img-fluid'} onClick={() => {
                                goToSlide(slideNb == 0 ? slides.length - 1 : slideNb - 1)
                            }}/> </Col>
                            <Col xs={{span: 8}} md={4} onClick={() => history.push(`/item/${curAsset?.slug}`)}
                                 className={'text-center text-white mt-3 d-flex align-items-center justify-content-center nftPeople'}>
                                <img
                                    src={
                                        curAsset?.currentOwner?.profilePicUrl
                                            ? curAsset?.currentOwner?.profilePicUrl
                                            : "./images/content/blue-avatar.png"
                                    }
                                    alt="Avatar"
                                />
                                <div className={'ms-2'}>
                                    @{curAsset?.currentOwner?.userName}
                                    <p>Creator</p>
                                </div>
                            </Col>
                            <Col xs={{span: 2}} md={{span: 1}} className={'slideControl'}><img
                                src={'/images/trends/right.svg'} className={'img-fluid'} onClick={() => {
                                goToSlide(slideNb == slides.length - 1 ? 0 : slideNb + 1)
                            }}/></Col>
                        </Row>
                    }
                </Container>
            }
        </Container>
    )
}

export default ThreedSlider;
