import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./RangeSlider.module.sass";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    width: "200%",
  },
});

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#FFF",
      },
      track: {
        color: "#FFF",
      },
      rail: {
        color: "#000",
      },
      valueLabel: {
        color: '#000',
      }
    },
  },
});

function valuetext(value) {
  return `${value}`;
}

const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  }
};

export default function RangeSlider({ setMin, setMax, resetRangeSlider }) {
  const classes = useStyles();
  const [value, setValue] = React.useState([0, 10000]);

  const handleChange = (event, priceRange) => {
    setMin(priceRange[0]);
    setMax(priceRange[1]);
    setValue(priceRange);
  };

  // To reset the range slider accordint to reset button
  useEffect(() => {
    // if (resetRangeSlider) {
    setValue([0, 10000]);
    // }
  }, [resetRangeSlider]);

  return (
    <div className={classes.root}>
      {/* <Typography id="range-slider" gutterBottom>
        Temperature range
      </Typography> */}
      <ThemeProvider theme={muiTheme}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          max={10000}
          valueLabelFormat={(value) => <div>{numFormatter(value)}</div>}
        />
      </ThemeProvider>
    </div>
  );
}
