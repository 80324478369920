import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Web3 from "web3";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";


import styles from "./User.module.sass";
import { axiosInstance } from "../../../utils/API";
import { setBasicAccountDetails } from "../../../redux/accountSlice";
import { setMagicUser } from "../../../redux/counterSlice";
import { maticWeb3, PopUpAlert } from "../../../controller/utils";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";


// const items = [
// 	{
// 		title: "Dashboard",
// 		url: "/dashboard",
// 	},
// 	// {
// 	//   title: "Purchased Assets",
// 	//   url: "/item",
// 	// },
// 	// {
// 	//   title: "My Account",
// 	//   url: "/my-account",
// 	// },
// 	{
// 		title: "Order Details",
// 		url: "/orderDetails",
// 	},
// 	// {
// 	//   url: "/ResoldAssets",
// 	//   title: "Active Listing",
// 	// },
// 	// {
// 	//   title: "Auctions",
// 	//   url: "/auction",
// 	// },
// 	// {
// 	//   title: "Wallet",
// 	//   url: "/circlePayment",
// 	// },
// 	// {
// 	//   title: "Dark theme",
// 	//   icon: "bulb",
// 	// },
// ];




const User = ({
	className,
	signedIn,
	loading,
	account,
	balance,
	sourcePage,
	onDisconnect,
	isMobile,
	onClose,
}) => {
	const [visible, setVisible] = useState(false);
	//const [details, setDetails] = useState(localStorage.getItem("cs_details"));
	const [userData, setUserData] = useState({});
	// const profile = useSelector((state) => state.counter.provider);
	//const tokenBal = localStorage.getItem("tokenbalance");
	const magicLinkUser = useSelector((state) => state.counter?.magicUser);
	const dispatch = useDispatch();

	const getConfig = () => {
		if (!localStorage.getItem("userToken")) {
			PopUpAlert("Alert", "No wallet connection found", "error");
			return;
		}
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};
		return config;
	};
	// console.log('sourcePage', sourcePage);
	React.useEffect(() => {
		const Call = async () => {
			if (signedIn && localStorage.getItem("userToken")) {
				//setDetails(JSON.parse(localStorage.getItem("cs_details")));
				await getDetails();
				await checkBalance();
			}
		};
		Call();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signedIn, userData?.profile]);

	const getDetails = async () => {
		const config = getConfig();
		await axiosInstance
			.get(`user/get-profile`, config)
			.then((res) => {
				const { profilePicUrl, email, firstName, lastName, status, id, kycStatus, isKYCLimitExceeded, emailVerified, isCreatedByAdmin } =
					res.data?.result;
				setUserData({ profile: profilePicUrl, name: firstName });
				let payload = {
					id: id,
					email: email,
					firstName: firstName,
					lastName: lastName,
					profilePicture: profilePicUrl,
					status: status,
					kycStatus: kycStatus,
					isKYCLimitExceeded: isKYCLimitExceeded,
					emailVerified: emailVerified,
					isCreatedByAdmin: isCreatedByAdmin
				};

				dispatch(setBasicAccountDetails(payload));
			})
			.catch(() => { });
	};

	const checkBalance = async () => {
		if (magicLinkUser?.publicAddress) {
			let magicBalance = await fetchBalance(magicLinkUser?.publicAddress);
			let userMetadata = { ...magicLinkUser, magicBalance: magicBalance };
			dispatch(setMagicUser(userMetadata));
		} else {
			// code here to metamask connect balance update
		}
	};

	const fetchBalance = (address) => {
		let web3 = new Web3(maticWeb3);
		return web3.eth.getBalance(address).then((bal) => web3.utils.fromWei(bal));
	};

	const useStyles = makeStyles((theme) => ({

		small: {
		  width: theme.spacing(3),
		  height: theme.spacing(3),
		},
		large: {
		  width: theme.spacing(7),
		  height: theme.spacing(7),
		},
	  }));

	const classes = useStyles();

	// const getBal = () => {
	// 	if (
	// 		typeof parseFloat(tokenBal) === "number" &&
	// 		!Number.isNaN(parseFloat(tokenBal))
	// 	)
	// 		return parseFloat(tokenBal)?.toFixed(4);
	// 	else return 0;
	// };

	const acceptingUserNameCriteria = { ...userData }
	// console.log('ACccepted ', acceptingUserNameCriteria);

	// console.log("userData?.profile", userData);
	return (
		<>
			{isMobile ? (
				<ListItem
					style={{
						//minWidth: "15.625rem",
						width: "15.625rem",
						padding: "0.75rem 1.25rem 1.25rem 1.25rem",
					}}
				>
					<ListItemIcon>
						<Avatar
							alt="Remy Sharp"
							src={
								signedIn
									? userData?.profile ||
									"/images/content/blue-avatar.png"
									: "/images/content/blue-avatar.png"
							}
						/>
					</ListItemIcon>
					<ListItemText primary={userData?.name || ""} />
					<div className={styles.closeIcon}>
						<CloseIcon style={{ color: "#E5E5E5" }} onClick={onClose} />
					</div>
				</ListItem>
			) : (
				<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
					<div className={cn(styles.user, className)}>
						<div
							className={styles.head}
							style={{ paddingRight: 4 }}
							onClick={() => {
								if (signedIn) setVisible(!visible);
								else document.getElementById("connectWallet").click();
							}}
						>
							<div>
								{signedIn ? (
									<>
										{/* <img
											src={
												userData?.profile ||
												"/images/content/blue-avatar.png"
											}
											alt="Avatar"
											style={{ objectFit: "cover" }}
										/> */}
										 <Avatar alt="avatar" src={userData?.profile ||
												"/images/content/blue-avatar.png"}/>

									</>
								) : (
									<Avatar  className = {classes.small} alt="avatar" src={"/images/content/blue-avatar.png"}/>
								)}
							</div>

						</div>


					</div>
				</OutsideClickHandler>
			)}
			{/* {
				signedIn &&

			<span

				 className={sourcePage ? styles.colorWhite : ""} style={{
					fontSize: 16,  fontWeight:500, fontFamily:"poppins",    width: "6.063rem", paddingLeft:"0.125rem",textAlign:"center",
				  display:"flex", justifyContent:"center"}}

			>{userData?.name?.substring(0, 10) ||''}</span>


			} */}
			{/* acceptingUserNameCriteria?.name.substring(0, 10) */}

			{/* color:"#878787" */}



		</>
	);
};

export default User;



