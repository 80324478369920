import {Col, Container, Row} from "react-bootstrap";

import './style.scss'
import useWindowSize from "../../../../common/hooks/useWindowSize";

function InfoTab({productDetails}) {

    const [screenWidth] = useWindowSize();

    return(
        <Container className={'infoTab'}>
            <Row>
                <Col>
                    <h4>Total Edition</h4>
                    <p> {productDetails?.totalEdition
                        ? productDetails?.totalEdition
                        : "-"}</p>
                </Col>
                <Col>
                    <h4>Total Left</h4>
                    <p> {productDetails?.availableStock
                        ? productDetails?.availableStock
                        : "-"}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>Token Standard</h4>
                    <p>{process.env.REACT_APP_TOKEN_STANDARD}</p>
                </Col>
                <Col>
                    <h4>Token ID</h4>
                    <p>{productDetails?.tokenId}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4>Contract Address</h4>
                    <p>
                        <a
                            href={`${process.env.REACT_APP_POLYGON_LINK}address/${process.env.REACT_APP_CONTRACT_ADDRESS_DEV}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#000000" }}
                        >
                            {" "}
                            {screenWidth > 1060
                                ? process.env.REACT_APP_CONTRACT_ADDRESS_DEV
                                : `${process.env.REACT_APP_CONTRACT_ADDRESS_DEV?.substring(
                                    0,
                                    4
                                )}....${process.env.REACT_APP_CONTRACT_ADDRESS_DEV?.substr(
                                    -4
                                )}`}
                            {/* {process.env.REACT_APP_CONTRACT_ADDRESS_DEV} */}
                        </a>{" "}
                    </p>
                </Col>
                <Col>
                    <h4>NFT Contract Address</h4>
                    <p>
                        {" "}
                        <a
                            href={`${process.env.REACT_APP_POLYGON_LINK}token/${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#000000" }}
                        >
                            {" "}
                            {screenWidth > 1060
                                ? process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV
                                : `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV?.substring(
                                    0,
                                    4
                                )}....${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV?.substr(
                                    -4
                                )}`}
                            {/* {process.env.REACT_APP_NFT_CONTRACT_ADDRESS_DEV} */}
                        </a>{" "}
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default InfoTab;
