import React, { useState, useEffect } from "react";
import API, { axiosInstance } from "../../utils/API";
import styles from "./dash.module.sass";
import CustomTable from "../../components/Table";
import { CircularProgress } from "@material-ui/core";

const UserCommissionTable = () => {
  const [params, setParams] = useState({ perPage: 10, page: 1 });
  const [commissionData, setCommissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let config = {
    headers: {
      Authorization: localStorage.getItem("userToken"),
    },
    params,
  };

  const getCommissionTableData = async () => {
    API.get("/asset/commission/history", config)
      .then((res) => {
        if (res?.data?.result?.records) {
          setCommissionData(res?.data?.result?.records);
          setIsLoading(false)
        }
        console.log("res", res?.data?.result?.records);
      })
      .catch((err) => {
        console.log("err", err);
      });
    };

    useEffect(() => {
      getCommissionTableData();
  }, []);

  return (
    <>
     {isLoading && (
              <div className={styles.loaderContent}>
                <CircularProgress style={{color: '#FFF'}} />
              </div>
      )}
      {commissionData?.length !== 0 ? (
        <>
          {/* <div className={styles.earningsContainer}>
            <div>
              <img src="" />
              <div>
                <h3>-- USD</h3>
                <p>Total Commission</p>
              </div>
            </div>
          </div> */}
          {/* <div className="history-table">
            <table>
              <thead>
                <tr>
                  <th>Asset Name</th>
                  <th>Asset Description</th>
                  <th>Royalty Commission</th>
                  <th>Total Price</th>
                  <th>Commission Amount</th>
                  <th>Cap Amount</th>
                  <th>Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {commissionData.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td>{el?.asset?.name}</td>
                      <td style={{maxWidth:'220px'}}>
                        {el?.asset?.description ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: el?.asset?.description,
                            }}
                          ></div>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td>
                        $
                        {el?.order?.royaltyCommission
                          ? Number(el?.order?.royaltyCommission).toFixed(4)
                          : "-"}
                      </td>
                      <td>
                        ${el?.order?.totalPrice ? el?.order?.totalPrice : "-"}
                      </td>
                      <td>
                        $
                        {el?.paidAmount
                          ? `${el?.paidAmount} (${el?.userAssetCommission?.percentage})`
                          : "-"}
                        %
                      </td>
                      <td>
                        $
                        {el?.userAssetCommission?.capAmount
                          ? Number(el?.userAssetCommission?.capAmount)?.toFixed(
                              4
                            )
                          : "-"}
                      </td>
                      <td>{el?.status}</td>
                      <td>{el?.remark ? el?.remark : "-"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}

          <CustomTable data = {commissionData}/>
        </>
      ) : (

       !isLoading && <div className={`noRecordsFound ${styles.noRecordsFound}`}>
          <span style={{color: '#FFF'}}>No records found.</span>
        </div>
      )}
    </>
  );
};

export default UserCommissionTable;
