import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Col, Container, Row} from "react-bootstrap";
import './style.scss'
import styles from "../Hero/Hero.module.sass";
import {Link} from "react-router-dom";

function Hero() {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        autoplaySpeed: 7000,
        autoplay: true,
        slidesToScroll: 1,
        className: 'liiHeroSlider',
        appendDots: (dots) => (
            <div style={{bottom: '60px'}}>
                <ul className="custom-dots">{dots}</ul>
            </div>
        ),
    };

    const jsonData =
        [
            // {
            //     "title": `DALIA MBARAK<br/>Exclusive Collection`,
            //     "description": "A visionary artist weaving tales through pixels, her NFT collection a testament to boundless creativity and digital innovation.",
            //     "image": "dalia.png",
            // },
            {
                "title": `Claim the <span>Digital</span> Collectible of Your Favorite <span>Celebrity</span>.`,
                "description": "Taking you on a trip to the future: Explore what celebrities are up to in 2080.",
                "image": "image1.jpg",
            }
        ]

    return (
        <div style={{
            minHeight: '100vh',
            position: 'relative',
            paddingTop: '90px',
            overflow: 'hidden'
        }}>
            <video controlsList="nodownload" id={"heroVideo"}
                   loop="true"
                   autoPlay="autoplay"
                   muted
                   playsInline>
                <source src={'/videos/lii_landing_1280_continues.mp4'}/>
            </video>
            <Slider {...sliderSettings}>

                {jsonData.map((item, index) => (
                    <div key={index} className="slide h-100">
                        <Container fluid style={{
                            // backgroundImage: `url(/images/artists/ragheb_bg.png)`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center right',
                            position: 'relative'
                        }}>

                            <Container className={'h-100'} style={{
                                position: 'relative',
                                zIndex: 2
                            }}>
                                <Row className={'d-flex align-items-center'} style={{zIndex: 2, minHeight: '88vh'}}>
                                    <Col lg={{span: 7, order: 1}}>
                                        <h1 dangerouslySetInnerHTML={{__html: item.title}}>
                                        </h1>
                                        <p>{item.description}</p>
                                        <a href={'/explore/'} className={'sliderCTA'}>Explore Now</a>
                                    </Col>
                                    {/*<Col lg={{span: 6, order: 2}} className="image-container">*/}
                                    {/*    <img src={'/images/dalia.png'} className={'img-fluid'}/>*/}

                                    {/*</Col>*/}
                                </Row>
                            </Container>
                        </Container>
                    </div>
                ))}

            </Slider>
            {/*<Link to="/search01/all" className={styles.emblem}>*/}
            {/*    <img src="/images/content/emblem.svg" alt="pic"></img>*/}
            {/*</Link>*/}

        </div>
    )
}

export default Hero;
