import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Lightbox from "./Lightbox";

const SocialShare = ({ show, setShow, productDetails }) => {
  const shareUrl = window.location.href;
  const [copiedText, setCopiedText] = useState(false);
  const item = productDetails;
  const cliptoCopy = () => {
    //    const successful = document.execCommand('copy')
    navigator.clipboard.writeText(shareUrl);
    setCopiedText(true);
  };

  return (
    <div
      className={styles.checkout + " " + styles.sharePopup}
      style={{ display: show ? "flex" : "none" }}
      onClick={() => setShow(false)}
    >
      <div
        className={styles.checkoutInner}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          type="button"
          className={styles.closeBtn}
          onClick={() => setShow(false)}
        >
          <img src={"/images/closeIcon.png"} alt={"closeicon"} />
        </button>
        <div style={{ marginTop: "1.25rem" }}>
          {item.mediaType === "audio" && (
            <div
              className="player-wrapper"
              style={{
                width: "200px",
                height: "200px",
                margin: "auto",
                position: "relative",
              }}
            >
              <img
                src={item?.audioThumbnail}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "0.625rem",
                  // objectFit: "scale-down",
                }}
                alt="thumbnail.png"
              />
              <audio
                controls
                controlsList="nodownload"
                style={{
                  position: "absolute",
                  bottom: "0.938rem",
                  left: 0,
                  height: 35,
                  width: "100%",
                }}
              >
                <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
              </audio>
            </div>
          )}
          {item.mediaType === "video" && (
            <div style={{ width: "200px", height: "200px", margin: "auto" }}>
              <video
                width="100%"
                height="100%"
                style={{ objectFit: "cover", borderRadius: "0.625rem" }}
                disablePictureInPicture
                controlsList="nodownload"
                controls
              >
                <source src={item?.mediaPreviewUrl} />
                Your browser does not support the video.
              </video>
            </div>
          )}
          {(item?.mediaType === "image" || item?.mediaType === "3D Model") && (
            <div style={{ width: "200px", height: "200px", margin: "auto" }}>
              <img
                src={item?.thumbnailUrl || item?.mediaPreviewUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "0.625rem",
                }}
                alt="thumbnail.png"
              />
            </div>
          )}
        </div>
        <h4>Share</h4>
        <div className={styles.socialBtns}>
          <FacebookShareButton url={shareUrl} hashtag={"Asly"}>
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} hashtag={"Asly"}>
            {/*<TwitterIcon size={40} round={true} />*/}
            <img src={"/images/xicon.png"} width={40}/>
          </TwitterShareButton>
          <TelegramShareButton url={shareUrl} hashtag={"Asly"}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton url={shareUrl} hashtag={"Asly"}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          {/* <EmailShareButton url={shareUrl} subject={'Asly'}>
                    <EmailIcon size={40} round={true} />
                </EmailShareButton> */}
        </div>
        <div className={styles.copyLink}>
          <label>Or copy link</label>
          <div className={styles.copyInput}>
            <input type="text" defaultValue={shareUrl} disabled />
            {copiedText ? (
              <span>Copied</span>
            ) : (
              <span onClick={() => cliptoCopy()}>Copy</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
