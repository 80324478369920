import React, { useEffect } from 'react';
import styles from './FullScreenModal.module.sass';

const FullScreenModal = ({ onClose, forceOpen, children }) => {
  useEffect(() => {
    if (forceOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when the modal is open
    } else {
      document.body.style.overflow = ''; // Restore scrolling when the modal is closed
    }

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [forceOpen]);

  const handleOverlayClick = (event) => {
    if ( event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={`${styles.fullscreenModalOverlay}${forceOpen ? ' active' : ''}`} onClick={handleOverlayClick}>
      <div className={styles.fullscreenModalContent}>
        {forceOpen && <button className={styles.modalCloseButton} onClick={onClose}>X</button>}
        {children}
      </div>
    </div>
  );
};

export default FullScreenModal;
