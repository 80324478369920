import React, { useState, useEffect } from "react";
import styles from "./collections.module.sass";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../common/hooks/useWindowSize";
import { CircularProgress } from "@material-ui/core";


const Collections = ({ sourcePage = null }) => {
  const [assets, setAssets] = React.useState();
  const [params, setParams] = useState({ limit: 10, page: 1 });
  const [count, setCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);

  const cryptoCurrency = process.env.REACT_APP_CRYPTO_CURRENCY;
  let data = [];
  let isCreator;
  const { id } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const [screenWidth] = useWindowSize();
  const status = useSelector((state) => state.counter.value);
  const euroValue = useSelector((state) => state.counter.euroValue) || 0;

  // page back handle using url parameter
  useEffect(() => {
    const urlParam = queryString.parse(search);
    if (urlParam?.pn) {
      setParams({ ...params, page: urlParam?.pn });
    }
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    setParams({ ...params, page: selectedPage + 1 });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    history.push(`/dashboard?tab=2&pn=${selectedPage + 1}`);
  };

  const getAllAssets = async () => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      params,
    };
    try {
      let userData = await axiosInstance.get("user/assets/collections", config);
      console.log(userData);
      setAssets(userData?.data?.result?.assets?.rows);
      setCount(userData?.data?.result?.metadata?.totalPages);
      setIsLoading(false)
    } catch (err) {
      console.log(err);
    }
  };

  const getWindowSize = () => {
    setWidth(window.innerWidth);
  };

  const handleView = (asset) => {
    history.push({
      pathname: "/checkoutReceipt",
      state: {
        details: asset,
        name: asset?.asset?.name,
        paymentType: asset.paymentType,
        orderTx: asset?.asset?.orderTx,
      },
    });
  };

  React.useEffect(() => {
    getAllAssets(id);
    window.addEventListener("resize", getWindowSize);

    return () => window.removeEventListener("resize", getWindowSize);
  }, [status, params]);

  console.log(status);

  return (
    <div className={cn("section", styles.section)}>
       {isLoading && (
              <div className={styles.loaderContent}>
                <CircularProgress style={{color: "#FFF"}} />
              </div>
      )}
      {status ? (
        <div className={styles.table}>
          {assets && assets?.map((item, index) => {
            return (
              <div className={styles.orderitem_main_container} onClick={() => history.push(`/collection/${item?.slug}`)}>
                <div
                  className={styles.orderitem}
                  style={{ padding: "0.313rem" }}
                  key={index}
                >
                  <div className={styles.itemdetails}>

                    <div className={styles.itemdetailsrow}>

                      {screenWidth > 766 && (
                        <div style={{width: '25%', marginRight: '4%'}}>
                          <div
                            className={styles.playerwrapper}
                            style={{ position: "relative", paddingTop: 0 }}
                          >

                            <img
                              src={item?.imagePath}
                              style={{
                                width: "100%",
                                height: 150,
                                objectFit: "cover",
                              }}
                              alt="collection.png"
                            />


                          </div>
                        </div>)}
                      {screenWidth > 766 && (
                          <div className={styles.displaycolumn}>
                            {item?.name}
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                {screenWidth < 767 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",

                      justifyContent: "space-between",
                    }}
                  >
                    <div style = {{width:'100%', textAlign:'center'}}>
                    <p>
                      {/* {item?.asset?.name} */}
                      {item?.name}
                    </p>

                    <img
                      src={item?.imagePath}
                      style={{
                        width: '100%',
                        height: 150,
                        objectFit: "cover",
                      }}
                      alt="collection.png"
                    />
                    </div>
                  </div>
                )}

              </div>
            );
          })}
          {assets?.length === 0 && (
            <div className={`noRecordsFound ${styles.noRecordsFound}`}>
              <span>No records found.</span>
            </div>
          )}
          {assets?.length > 0 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={count}
              forcePage={params?.page - 1}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={cn(
                styles.pagination__link,
                "page_switch"
              )}
              nextLinkClassName={cn(styles.pagination__link, "page_switch")}
              pageClassName={"page_no"}
              disabledClassName={styles.pagination__link__disabled}
              activeClassName={styles.pagination__link__active}
              pageRangeDisplayed={width < 510 ? 1 : 3}
              marginPagesDisplayed={width < 510 ? 1 : 3}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            width: "90%",
            padding: "1.875rem 5%",
            margin: "auto",
            fontSize: '0.938rem',
          }}
        >
          Login to view details
        </div>
      )}
    </div>
  );
};

export default Collections;
