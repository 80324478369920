import { useHistory } from "react-router";
import styles from "../../ViewProfile/viewprofile.module.sass";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatPrice } from "../../../controller/utils";
const CollectionCard = ({ details, currentTab, profileDetails }) => {
  console.log(
    "collection.details, current tab, profileDetails",
    details,
    currentTab,
    profileDetails
  );
  const history = useHistory();
  const walletStatus = useSelector((state) => state?.counter?.value);
  const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);
  const item = details.Assets;
  const cardImageValue = (type) => {
    return (
      <img
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(`/collection/${details?.slug}`);
        }}
        src={details?.imagePath || details?.Asset?.mediaPreviewUrl}
        alt="logo"
      ></img>
    );
  };

  const getPrice = (asset) => {
    if (asset?.saleType == "fixed") {
      return asset?.regularPrice;
    } else {
      return asset?.auction?.currentBid;
    }
  };
  return (
    <>
      <div className={styles.card} onClick={() => {
;
          history.push(`/collection/${details?.slug}`);
      }}>
        <div
          className={styles.previewImage}
        >
          {cardImageValue(details?.Asset?.mediaType)}

        </div>
        <div>
          <div className={styles.collectionMainInfo}>
            <div className={styles.collectionName}>{details?.name}</div>
            <div className={styles.assetCount}>{details?.assetCount} <p>Assets</p></div>
          </div>
          <div className={styles.collectionDescription}>
            <h4>Description</h4>
            <div className={styles.description}>
              {details?.description.substr(0,75)}
            </div>
          </div>
          {/*<div className={styles.buyNowOrBidNow}>*/}
          {/*  <button*/}
          {/*    className={styles.buy}*/}
          {/*    onClick={() => {*/}
          {/*      history.push(`/collectionDetails/${details?.id}`);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    View*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default CollectionCard;
