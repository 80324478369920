import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./CirclePayment.module.sass";
import WalletDisplayBar from "./WalletDisplayBar";
import TransactionHistory from "./TransactionHistory";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw/Withdraw";
import { axiosInstance } from "../../utils/API";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCirlceDetails, setCirlceId } from "../../redux/circlePaymentSlice";
import { setRefresh } from "../../redux/utilitySlice";
import { PopUpAlert } from "../../controller/utils";
import useWindowSize from "../../common/hooks/useWindowSize";
import { getUsdcBalanceFromAccount } from "../../utils/balance";

function CirclePaymentIndex({isCreator}) {
	const [openDepoist, setOpenDepoist] = useState(false);
	const [openWithdraw, setOpenWithdraw] = useState(false);
	const [refreshPage, setRefreshPage] = useState(true);
	const dispatch = useDispatch();
	const loginStatus = useSelector((state) => state.counter.value);
	let circleBalance = useSelector((state) => state.circlePayment.balance);
	let walletId = useSelector((state) => state.circlePayment.walletId);
	let refresh = useSelector((state) => state.utilityReducer.refresh);
	const history = useHistory();
	const [screenwidth] = useWindowSize();

	const userAccount = useSelector((state) => state?.counter?.user);
	const [usdcWalletBalance, setUsdcWalletBalance]= useState(null)
	  useEffect(() => {
		fetchUsdBalance()
	  }, [userAccount?.account?.[0]]);

	  const fetchUsdBalance = async () => {
		if (userAccount?.account?.[0]) {
		  let address = userAccount?.account?.[0];
		  let balance = await getUsdcBalanceFromAccount(address);
		  setUsdcWalletBalance(balance)
		}
	  };

	useEffect(() => {
		const getAccountDetails = async () => {
			await getAccountStatus();
			if (!walletId) {
				await createCircleWallet();
			}
			// await getCircleAccount();
		};

		const checkLoggedIn = () => {
			const token = localStorage.getItem("userToken");
			console.log({ token });
			if (!token) {
				// history.push("/Marketplace");
				PopUpAlert("info", "Please login and proceed to wallet.", "error").then(
					(res) => {
						document.getElementById("connectWallet").click();

						return false;
					}
				);
			}
			return true;
		};
		const isLoggedIn = !checkLoggedIn();
		getAccountDetails();

		// return () => {

		// }
	}, [loginStatus]);

	const getAccountStatus = async () => {
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};
		const result = await axiosInstance
			.get("/user/wallets/balance", config)
			.then((result) => {
				// getETHPrice();
				setRefreshPage(!refreshPage);
				dispatch(setCirlceDetails(result.data?.result));
				dispatch(setRefresh(!refresh));
			})
			.catch((err) => {
				if (err?.response?.status == 401)
					PopUpAlert("Alert", err.response?.data?.message);
			});
	};

	const getCircleAccount = async () => {
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};

		let payload = {
			currency: "USD",
			chain: "ETH",
		};
		if (walletId) {
			const result = await axiosInstance
				.post(`/user/blockchain/${walletId}/address`, payload, config)
				.then((result) => {
					console.log("Payload", result);
					dispatch(setCirlceId(result.data?.result));
				})
				.catch((err) => {
					if (err?.response?.status == 401)
						PopUpAlert("Alert", err.response?.data?.message);
				});
		}
	};

	const createCircleWallet = async () => {
		let config = {
			headers: {
				Authorization: localStorage.getItem("userToken"),
			},
		};

		const result = await axiosInstance
			.post("/user/wallets", {}, config)
			.then((result) => {
				console.log("Result createCirclewallet", result);
				dispatch(setCirlceDetails(result.data?.result));
				setRefreshPage(!refreshPage);
			})
			.catch((err) => {});
	};

	const getBackButton = () => {
		return (
			<div
				className={styles.backArrowContainer}
				onClick={() => history.goBack()}
			>
				<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="40" height="40" rx="20" fill="#3E2F5F"/>
					<path d="M23.6898 15.7408L19.4368 20.0288L23.6898 24.2568C23.7877 24.3483 23.8657 24.4589 23.9191 24.5818C23.9725 24.7047 24 24.8373 24 24.9713C24 25.1053 23.9725 25.2378 23.9191 25.3607C23.8657 25.4836 23.7877 25.5943 23.6898 25.6858C23.5999 25.7843 23.4903 25.863 23.3683 25.9169C23.2462 25.9707 23.1142 25.9985 22.9808 25.9985C22.8474 25.9985 22.7154 25.9707 22.5934 25.9169C22.4713 25.863 22.3618 25.7843 22.2718 25.6858L17.3098 20.7428C17.2119 20.6513 17.1339 20.5406 17.0805 20.4177C17.0272 20.2948 16.9996 20.1623 16.9996 20.0283C16.9996 19.8943 17.0272 19.7617 17.0805 19.6388C17.1339 19.5159 17.2119 19.4053 17.3098 19.3138L22.2718 14.3138C22.3616 14.2151 22.471 14.1362 22.593 14.0822C22.715 14.0282 22.8469 14.0002 22.9804 14C23.1138 13.9998 23.2458 14.0274 23.3679 14.0811C23.4901 14.1348 23.5997 14.2133 23.6898 14.3118C23.8733 14.5058 23.9799 14.7599 23.9898 15.0268C23.9905 15.16 23.9643 15.292 23.9127 15.4149C23.8612 15.5377 23.7854 15.6489 23.6898 15.7418L23.6898 15.7408Z" fill="url(#paint0_linear_187_15756)"/>
					<defs>
						<linearGradient id="paint0_linear_187_15756" x1="23.489" y1="16.0757" x2="15.0093" y2="19.7529" gradientUnits="userSpaceOnUse">
							<stop stop-color="#04DCFD"/>
							<stop offset="1" stop-color="#1F90FA"/>
						</linearGradient>
					</defs>
				</svg>
			</div>
		);
	};

	return (
		<>
			{loginStatus ? (
				<div className={cn("container", styles.container)}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<WalletDisplayBar
							openDepositModal={() => setOpenDepoist(!openDepoist)}
							openWithdrawModal={() => setOpenWithdraw(!openWithdraw)}
							usdcWalletBalance = {Number(usdcWalletBalance) ? Number(usdcWalletBalance).toFixed(2) : "0.00"}
							isCreator={isCreator}
							// refreshPage={refreshPage}
						/>
					</div>
					<TransactionHistory
						setRefreshPage={setRefreshPage}
						refreshPage={refreshPage}
						screenwidth={screenwidth}
					/>
					{/* <div style={{ display: "flex", justifyContent: "center", marginTop: '1.25rem' }}>
          <Deposit />
        </div> */}
					{openDepoist ? (
						<Deposit
							closePopup={() => setOpenDepoist(false)}
							setRefreshPage={setRefreshPage}
							refreshPage={refreshPage}
						/>
					) : null}
					{openWithdraw ? (
						<Withdraw
							closePopup={() => setOpenWithdraw(false)}
							setRefreshPage={setRefreshPage}
							refreshPage={refreshPage}
							usdcWalletBalance = {Number(usdcWalletBalance) ? (Number(usdcWalletBalance).toFixed(2)) : "0.00"}
						/>
					) : null}
				</div>
			) : (
				<div>
					<div style={{ textAlign: "center", fontSize: "0.938rem" }}>
						Login to view wallet.
					</div>
				</div>
			)}
		</>
	);
}

export default CirclePaymentIndex;
