import React, {useState, useEffect} from "react";
import styles from "./requestednft.module.sass";
import Swal from "sweetalert2";
import {axiosInstance} from "../../utils/API";
import {useParams, useHistory} from "react-router";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";
import cn from "classnames";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import {current_datetime} from "../../controller/utils";
import {formatPrice} from "../../controller/utils";
import useWindowSize from "../../common/hooks/useWindowSize";
import {CircularProgress} from "@material-ui/core";
import moment from "moment";
import {OpenInBrowserTwoTone, OpenInNew} from "@material-ui/icons";

const RequestedNFTs = ({sourcePage = null}) => {
    const [assets, setAssets] = React.useState();
    const [params, setParams] = useState({limit: 10, page: 1});
    const [count, setCount] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(true);
    const cryptoCurrency = process.env.REACT_APP_CRYPTO_CURRENCY;
    const polygon =
        process.env.NODE_ENV === "development"
            ? "https://mumbai.polygonscan.com/tx/"
            : "https://polygonscan.com/tx/";
    const {id} = useParams();
    const history = useHistory();
    const {search} = useLocation();
    const [screenWidth] = useWindowSize();
    const status = useSelector((state) => state.counter.value);
    const euroValue = useSelector((state) => state.counter.euroValue) || 0;

    // page back handle using url parameter
    useEffect(() => {
        const urlParam = queryString.parse(search);
        if (urlParam?.pn) {
            setParams({...params, page: urlParam?.pn});
        }
    }, []);

    const handlePageClick = ({selected: selectedPage}) => {
        setParams({...params, page: selectedPage + 1});
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        const urlParams = new URLSearchParams(window.location.search);
        let tabNo = '9'
        if (urlParams.has('tab') && urlParams.get('tab') === '3') {
            tabNo = '3'
        }
        history.push(`/dashboard?tab=${tabNo}&pn=${selectedPage + 1}`);
    };

    const getAllAssets = async () => {
        let config = {
            headers: {
                Authorization: localStorage.getItem("userToken"),
            },
            params,
        };
        try {
            let userData = await axiosInstance.get("user/airdrop/getAllRequests", config);
            console.log('userData?.data?.result?.orders?.rows', userData?.data)
            setAssets(userData?.data?.result?.rows);
            setCount(userData?.data?.result?.totalPages);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const getWindowSize = () => {
        setWidth(window.innerWidth);
    };

    const handleView = (asset) => {
        history.push(`/item/${asset?.assetId}`)
    };

    React.useEffect(() => {
        getAllAssets(id);
        window.addEventListener("resize", getWindowSize);

        return () => window.removeEventListener("resize", getWindowSize);
    }, [status, params]);

    return (
        <div className={cn("section", styles.section)}>
            <div className={cn("container", styles.container)}>
                {status ? (
                    <div>
                        {sourcePage != "myProfile" && (
                            <div
                                className={styles.title}
                                style={{
                                    width: "100%",
                                    padding: "1.25rem 0px",
                                    marginBottom: 45,
                                    display: "flex",
                                    flexDirection: "row",
                                    fontWeight: 600,
                                    fontSize: "1.875rem",
                                }}
                            >
                                {/* <div
                className={styles.backButtonWrapper}
                onClick={() => history.goBack()}
              >
                <img
                  src="/images/content/backArrow.svg"
                  height={40}
                  width={40}
                  style={{ marginRight: "0.625rem", opacity: 0 }}
                  alt="back"
                />
              </div> */}
                                Transaction history
                            </div>
                        )}
                        {isLoading && (
                            <div className={styles.loaderContent}>
                                <CircularProgress style={{color: "#FFF"}}/>
                            </div>
                        )}
                        {assets?.map((asset, index) => {
                            const item = asset.asset;
                            return (
                                <div className={styles.orderitem_main_container}>
                                    <div
                                        className={styles.orderitem}
                                        style={{padding: "0.313rem"}}
                                        key={index}
                                    >
                                        <div>
                                            {item.mediaType === "audio" && (
                                                <div
                                                    className={styles.playerwrapper}
                                                    style={{position: "relative", paddingTop: 0}}
                                                >
                                                    {/* <div> */}
                                                    <img
                                                        src={item?.audioThumbnail}
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        alt="thumbnail.png"
                                                    />
                                                    <audio
                                                        controls
                                                        controlsList="nodownload"
                                                        style={{
                                                            position: "absolute",
                                                            bottom: 0,
                                                            left: 0,
                                                            height: 25,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <source
                                                            src={item?.mediaPreviewUrl}
                                                            type="audio/mpeg"
                                                        />
                                                    </audio>
                                                    {/* </div> */}
                                                </div>
                                            )}
                                            {item.mediaType === "video" && (
                                                <div
                                                    className={styles.playerwrapper}
                                                    // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                                                    style={{position: "relative", paddingTop: 0}}
                                                >
                                                    <video
                                                        width="100%"
                                                        height="100%"
                                                        style={{objectFit: "cover", borderRadius: "0.313rem"}}
                                                        disablePictureInPicture
                                                        controlsList="nodownload"
                                                        loop="true"
                                                        autoplay="autoplay"
                                                        controls={true}
                                                        muted
                                                        playsInline
                                                    >
                                                        <source src={item?.mediaPreviewUrl}/>
                                                        Your browser does not support the video.
                                                    </video>
                                                    {/* <ReactPlayer
                            url={item?.mediaPreviewUrl}
                            width="100%"
                            height="100%"
                            controls={true}
                            light={false}
                            style={{ objectFit: "cover" }}
                            config={{
                              file: {
                                attributes: {
                                  disablePictureInPicture: true,
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          /> */}
                                                </div>
                                            )}
                                            {(item?.mediaType === "image" ||
                                                item?.mediaType === "3D Model") && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    className={styles.playerwrapper}
                                                >
                                                    <img
                                                        src={item.thumbnailUrl || item?.mediaPreviewUrl}
                                                        style={{
                                                            width: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        alt="thumbnail.png"
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className={styles.itemdetails}>
                                            <div className={styles.itemdetailsrow}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h4 data-id="itemname">
                                                        {asset?.status == "success" ? `${item?.name} #${item.currentEdition ? item.currentEdition : "1"}/${item.totalEdition}` : item?.name}
                                                        {asset?.txnHash && <a
                                                            href={`
                              ${process.env.REACT_APP_POLYGON_LINK}tx/${asset?.txnHash}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{
                                                                width: "auto",
                                                                marginLeft: "0.4em",
                                                            }}
                                                            data-id="viewScan"
                                                        >
                                                            <OpenInNew color={'#7342dc'}/>

                                                        </a>}
                                                    </h4>

                                                    <div className={styles.itemdetailsrow}>
                                                        {/*{screenWidth > 766 && (*/}
                                                            <div style={{marginBottom: '.625rem'}}>
                                                                <p className={styles.bold}>Requested Date :</p>

                                                                <b>
                                                                    {moment.utc(asset?.createdAt).local().format('DD MMM YYYY    h:mm:ss A') || "-"}
                                                                </b>
                                                            </div>
                                                        {/*)}*/}
                                                        {/*{screenWidth > 766 && (*/}
                                                            <div data-id="o_status">
                                                                <p className={styles.bold}>Status</p>
                                                                <b>
                                                                    {asset?.status === 'inactive' &&
                                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M10.5 20C12.4778 20 14.4112 19.4135 16.0557 18.3147C17.7002 17.2159 18.9819 15.6541 19.7388 13.8268C20.4957 11.9996 20.6937 9.98891 20.3078 8.0491C19.922 6.10929 18.9696 4.32746 17.5711 2.92894C16.1725 1.53041 14.3907 0.578004 12.4509 0.192152C10.5111 -0.1937 8.50043 0.00433286 6.67316 0.761209C4.8459 1.51809 3.28412 2.79981 2.1853 4.4443C1.08649 6.08879 0.5 8.02219 0.5 10C0.5 12.6522 1.55357 15.1957 3.42893 17.0711C5.3043 18.9464 7.84783 20 10.5 20ZM9.66667 5C9.66667 4.77899 9.75446 4.56703 9.91074 4.41075C10.067 4.25447 10.279 4.16667 10.5 4.16667C10.721 4.16667 10.933 4.25447 11.0893 4.41075C11.2455 4.56703 11.3333 4.77899 11.3333 5V11.6667C11.3333 11.8877 11.2455 12.0996 11.0893 12.2559C10.933 12.4122 10.721 12.5 10.5 12.5C10.279 12.5 10.067 12.4122 9.91074 12.2559C9.75446 12.0996 9.66667 11.8877 9.66667 11.6667V5ZM10.5 15C10.6648 15 10.8259 15.0489 10.963 15.1404C11.1 15.232 11.2068 15.3622 11.2699 15.5144C11.333 15.6667 11.3495 15.8343 11.3173 15.9959C11.2852 16.1576 11.2058 16.306 11.0893 16.4226C10.9727 16.5391 10.8242 16.6185 10.6626 16.6507C10.5009 16.6828 10.3334 16.6663 10.1811 16.6032C10.0288 16.5402 9.89867 16.4334 9.80711 16.2963C9.71554 16.1593 9.66667 15.9982 9.66667 15.8333C9.66667 15.6123 9.75446 15.4004 9.91074 15.2441C10.067 15.0878 10.279 15 10.5 15Z"
                                                                                fill="#FF2D2D"/>
                                                                        </svg>
                                                                    }
                                                                    {(asset?.status === 'initialized' || asset?.status === 'active') &&
                                                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01809 6.3459 3.26121 8.17316C2.50433 10.0004 2.3063 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42894 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8078C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12C22.4971 9.34871 21.4426 6.80684 19.5679 4.9321C17.6932 3.05736 15.1513 2.00287 12.5 2ZM15.8333 12.8333H12.5C12.279 12.8333 12.067 12.7455 11.9107 12.5893C11.7545 12.433 11.6667 12.221 11.6667 12V7C11.6667 6.77899 11.7545 6.56702 11.9107 6.41074C12.067 6.25446 12.279 6.16667 12.5 6.16667C12.721 6.16667 12.933 6.25446 13.0893 6.41074C13.2455 6.56702 13.3333 6.77899 13.3333 7V11.1667H15.8333C16.0543 11.1667 16.2663 11.2545 16.4226 11.4107C16.5789 11.567 16.6667 11.779 16.6667 12C16.6667 12.221 16.5789 12.433 16.4226 12.5893C16.2663 12.7455 16.0543 12.8333 15.8333 12.8333Z"
                                                                                fill="#E78818"/>
                                                                        </svg>
                                                                    }
                                                                    {(asset?.status === 'completed' || asset?.status === 'success') &&
                                                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M12.5 0C10.1266 0 7.80655 0.703788 5.83316 2.02236C3.85977 3.34094 2.3217 5.21509 1.41345 7.4078C0.505199 9.60051 0.267559 12.0133 0.730582 14.3411C1.1936 16.6689 2.33649 18.8071 4.01472 20.4853C5.69295 22.1635 7.83115 23.3064 10.1589 23.7694C12.4867 24.2324 14.8995 23.9948 17.0922 23.0866C19.2849 22.1783 21.1591 20.6402 22.4776 18.6668C23.7962 16.6935 24.5 14.3734 24.5 12C24.4966 8.81846 23.2312 5.76821 20.9815 3.51852C18.7318 1.26883 15.6815 0.00344108 12.5 0ZM12.409 15.419C12.2241 15.6041 12.0045 15.7507 11.7626 15.8504C11.5208 15.9501 11.2616 16.001 11 16C10.7371 16.0003 10.4767 15.9488 10.2338 15.8485C9.9908 15.7481 9.77 15.6008 9.58401 15.415L6.80001 12.718L8.20001 11.281L10.993 13.988L16.802 8.288L18.202 9.713L12.409 15.419Z" fill="#43C914"/>
                                                                        </svg>
                                                                    }{" "}
                                                                    {asset?.status == "inactive" ? "Not Selected " : asset?.status == "active" ? "pending" : asset.status == 'initialized' ? "In progress" : asset.status}    </b>
                                                            </div>
                                                        {/*)}*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                              {/*      {screenWidth < 767 && (*/}
                              {/*          <div*/}
                              {/*              style={{*/}
                              {/*                  display: "flex",*/}
                              {/*                  alignItems: "center",*/}

                              {/*                  justifyContent: "space-between",*/}
                              {/*              }}*/}
                              {/*          >*/}
                              {/*              <p className={styles.createdAt}>*/}
                              {/*                  {moment.utc(asset?.createdAt).local().format('DD MMM YYYY    h:mm:ss A') || "-"}*/}
                              {/*              </p>*/}

                              {/*              <div*/}
                              {/*                  className={*/}
                              {/*                      asset?.status == "initialized" ||*/}
                              {/*                      asset?.status == "active"*/}
                              {/*                          ? styles.pending*/}
                              {/*                          : asset?.status == "inactive"*/}
                              {/*                              ? styles.failed*/}
                              {/*                              : styles.success*/}
                              {/*                  }*/}
                              {/*              >*/}
                              {/*                  <p> {asset?.status}</p>*/}
                              {/*              </div>*/}
                              {/*          </div>*/}
                              {/*      )}*/}
                              {/*      {screenWidth < 767 && (*/}
                              {/*          <div*/}
                              {/*              style={{*/}
                              {/*                  display: "flex",*/}
                              {/*                  justifyContent: "space-between",*/}
                              {/*                  alignItems: "center",*/}
                              {/*                  marginTop: "0.625rem",*/}
                              {/*              }}*/}
                              {/*          >*/}
                              {/*              {asset?.txnHash && asset.status == 'completed' ? (*/}
                              {/*                  <div className={styles.viewOnPolygon}>*/}
                              {/*                      <a*/}
                              {/*                          href={`*/}
                              {/*${process.env.REACT_APP_POLYGON_LINK}tx/${asset?.txnHash}`}*/}
                              {/*                          target="_blank"*/}
                              {/*                          rel="noreferrer"*/}
                              {/*                          style={{color: "black"}}*/}
                              {/*                          data-id="viewScan"*/}
                              {/*                      >*/}
                              {/*                          <img src="/images/polygon.svg" alt="polygon"/>*/}
                              {/*                          View on polygon scan*/}
                              {/*                      </a>*/}
                              {/*                  </div>*/}
                              {/*              ) : (*/}
                              {/*                  <div></div>*/}
                              {/*              )}*/}

                              {/*          </div>*/}
                              {/*      )}*/}
                                </div>
                            );
                        })}
                        {assets?.length === 0 && (
                            <div className={`noRecordsFound ${styles.noRecordsFound}`}>
                                <span>No records found.</span>
                            </div>
                        )}
                        {!isLoading && assets?.length > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={count}
                                forcePage={params?.page - 1}
                                onPageChange={handlePageClick}
                                containerClassName={styles.pagination}
                                previousLinkClassName={cn(
                                    styles.pagination__link,
                                    "page_switch"
                                )}
                                nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                                pageClassName={"page_no"}
                                disabledClassName={styles.pagination__link__disabled}
                                activeClassName={styles.pagination__link__active}
                                pageRangeDisplayed={width < 510 ? 1 : 3}
                                marginPagesDisplayed={width < 510 ? 1 : 3}
                            />
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            width: "90%",
                            padding: "1.875rem 5%",
                            margin: "auto",
                            fontSize: "0.938rem",
                        }}
                    >
                        Login to view details
                    </div>
                )}
            </div>
        </div>
    );
};

export default RequestedNFTs;
