import useWindowSize from "../../../../common/hooks/useWindowSize";
import './style.scss'
import {useHistory} from "react-router";

function PreviousOwnersTab({owners}) {

    const [screenWidth] = useWindowSize();
    const history = useHistory();

    return(
        <div className={'assetOwners'}>
            {owners.map((t) => {
                return <div className={'assetOwner'} onClick={() => history.push(`/viewProfile/${t.userId}`)}>
                    <div className={'ownerPic'}><img src={t.profilePicUrl?t.profilePicUrl:"/images/content/blue-avatar.png"} /></div>
                    <div className={'ownerInfo'}>
                        <div className={'ownerName'}>{t.firstName} {t.lastName}</div>
                        <div className={'ownerUsername'}>@{t.userName}</div>
                    </div>
                </div>
            })}
            {owners.length == 0 && <span style={{color: 'white'}}>No previous owners yet!</span>}
        </div>
    )
}

export default PreviousOwnersTab;
