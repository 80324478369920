import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../utils/API";

import styles from "./Earnings.module.sass";
import EarningsSale from "./components/EarningsSale";

const Earnings = () => {
  const isLogin = useSelector((state) => state.counter.value);
  const [menuIndex, setMenuIndex] = useState(0);
  const [params, setParams] = useState({ limit: 10, page: 1, type: 'sale' });
  // const [page, setPage] = useState(1);
  const [data, setData] = useState("")
  const [count, setCount] = useState(0);
  const accountDetails = useSelector((state) => state.accountReducer);
  const history = useHistory();
  const isCreator = accountDetails?.isCreatedByAdmin

  const menuItems = isCreator ? [
    {
      title: "From Sale",
      id: 0,
    },
    {
      title: "From Royalty",
      id: 1,
    }
  ] : [{
    title: "From Sale",
    id: 0,
  },];

  const getEarnings = async (type) => {
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
      params,
    };
    try {
      let earningData = await axiosInstance.get("/user/analytics/earnings", config);
      console.log("qqqqqqqqqqqqq", earningData?.data);
      setData(earningData?.data?.earnings)
      setCount(earningData?.data?.metadata?.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  console.log(count);

  useEffect(() => {
    history.push({
      pathname: "/dashboard",
      search: "?tab=13&subtab=0",
    });
  }, [])
  useEffect(() => {
    getEarnings()
  }, [params])


  return (
    <div className={styles.EarningsContainer}>
      {isLogin ? (
        <>
          <div className={styles.menuWrapper}>
            {menuItems?.map((item, index) => (
              <div
                className={
                  menuIndex == item?.id ? styles.selected : styles.nonSelected
                }
                onClick={() => {
                  setMenuIndex(item.id);
                  // setPage(1);
                  history.push(`/dashboard?tab=13&subtab=${item?.id}`);
                  if (item?.id === 0) {
                    setParams({ limit: 10, page: 1, type: 'sale' });
                  }
                  else {
                    setParams({ limit: 10, page: 1, type: 'royalty' });
                  }
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
          {/* for created tabs */}
          {menuIndex == 0 && (
            <EarningsSale
              count={count}
              isCreator={false}
              data={data}
              params={params}
              setParams={setParams}
              menuIndex={menuIndex}
            />
          )}
          {menuIndex == 1 && (
            <EarningsSale
              count={count}
              isCreator={isCreator}
              params={params}
              data={data}
              setParams={setParams}
              menuIndex={menuIndex}
            />
          )}
        </>
      ) : (
        <span>Login to view earnings.</span>
      )}
    </div>
  );
}

export default Earnings;
